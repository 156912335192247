import AuthService from "../session/AuthService";

let allowedDomains = [
  "artest.betalearnings.com"
];

let allowedProductionDomains = ["vitalmri.com"
];

const testingDomains = ["localhost","127.0.0.1"]

let URL = "";
if (["localhost", "127.0.0.1"].includes(window.location.hostname)) {
  URL = "http://localhost:8081/"; //local

  // URL = "https://artest.betalearnings.com/FullbodyMRI_MW/"; //testing
} else if (allowedDomains.includes(window.location.hostname)) {
  URL = `https://${window.location.hostname}/vitalmri_MW/`;
}else if(allowedProductionDomains.includes(window.location.hostname)){
  URL = `https://${window.location.hostname}/vitalmri_MW/`;

} else {
  URL = "https://noaccess.com/youHaveNoAccess/";
}

export const API_URL = URL;

//####################Local-Betamonks####################//
// export const GOOGLE_MAP_API_KEY = "AIzaSyC0g6aHYB1U_n4nN4uWXUoKIkOqCWKlxqM";

//####################Testing####################//
//export const GOOGLE_MAP_API_KEY = "AIzaSyDc9r3A6YlGG9Wb4rTQctLzUN2nY3EarAs";

//export const GOOGLE_MAP_API_KEY = "AdIzaSyCUVQ7sZJc4dafsaffOwMk0W0iPYU0YOgF_jlN4e4";
//####################Production####################//
// export const GOOGLE_MAP_API_KEY = "AIzaSyCUVQ7sZJc4OwMk0W0iPYU0YOgF_jlN4e4";

export const HTTP_HEADERS = {
  "Content-Type": "application/json",
  Authorization: "PRO-JWT",
};

class HTTP {
  HEADERS() {
    return {
      "Content-Type": "application/json",
      Accept: "*/*",
      Authorization: "JWT fefege...",
    };
  }
  AUTH_HEADERS() {
    return {
      "Content-Type": "application/json",
      Accept: "*/*",
      Authorization: AuthService.getToken(),
    };
  }
}

let razorPaymentKey = "rzp_helloWorldKey";
if (testingDomains.includes(window.location.hostname)) {
  razorPaymentKey = "rzp_test_SIluI3wA6Gdr6a";
} else if (allowedDomains.includes(window.location.hostname)) {
  razorPaymentKey = "rzp_test_SIluI3wA6Gdr6a";
}else if (allowedProductionDomains.includes(window.location.hostname)) {
  razorPaymentKey = "rzp_live_0G170QQxQMJRk2";
}



export const RAZORPAY_KEY = razorPaymentKey;


export default new HTTP();
