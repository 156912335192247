import React from "react";
import {MriHeader} from "../../MRI/WhyMRI/MriHeader";
import OrganScan from "../../MRI/WhyMRI/OrganScan";

const MriPage = () => {
  return (
    <>
      {MriHeader()}
      <OrganScan />
    </>
  );
};

export default MriPage;
