import React from "react";
import { Route, Switch } from "react-router-dom";
import GoToTop from "../customFunctions/GoToTop.js";
import Appointment from "../screens/Appoinment/Appoinment.jsx";
import Pricing from "../screens/Appoinment/Pricing.jsx";
import AboutBanner from "../screens/ContactUs/AboutBanner.jsx";
import Blog from "../screens/ContactUs/Blog.jsx";
import ContactUs from "../screens/ContactUs/ContactUs.jsx";
import FaQuestions from "../screens/FAQ/FaQuestions.jsx";
import YourRisk from "../screens/KnowRisk/YourRisk.jsx";
import OurLocation from "../screens/Location/OurLocation.jsx";
import NotFound from "../screens/NotFound/NotFound.jsx";
import Home from "../screens/RenderComponent/Home/HomePage.jsx";
import MriPage from "../screens/RenderComponent/Home/MriPage.jsx";
import TemporaryOurLocation from "../screens/Location/TemporaryOurLocation.jsx";
import AppointmentBooked from "../screens/ContactUs/Achivement/AppointmentBooked.jsx";


function NavigationRouter(){
  
// console.log("base::::",AuthService.getBaseUrl());
    return (
      <div>
      <Switch>
        <Route path="/" exact>
          <Home />
        </Route> 
        <Route path="/MRI" exact>
          <MriPage />
        </Route>
        <Route path="/Appointment" exact>
          <Appointment />
        </Route>
        <Route path="/OurLocations" exact>
          {/* <OurLocation /> */}
          <TemporaryOurLocation/>
        </Route>
        <Route path="/FAQ" exact>
          <FaQuestions />
        </Route>
        <Route path="/Pricing" exact>
          <Pricing />
        </Route>
        <Route path="/ContactUs" exact>
          <ContactUs/>
        </Route>
        <Route path="/Blog" exact>
          <Blog/>
        </Route>
        <Route path="/AboutUs" exact>
          <AboutBanner/>
        </Route>
        <Route path="/Knowyourrisk" exact>
          <YourRisk/>
        </Route>
        <Route path="/appointment-booked" exact>
          <AppointmentBooked/>
        </Route>

        <Route path='*' >
           <NotFound />
        </Route>
        
      </Switch>
      <GoToTop />
      </div>
    );
  
}


export default NavigationRouter;