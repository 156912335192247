import React, { useEffect, useRef, useState } from "react";
import {
  Button,
  Col,
  Container,
  Form,
  Row,
  ToggleButton,
  ToggleButtonGroup,
} from "react-bootstrap";
import Swal from "sweetalert2";
import BookingService from "../../api/BookingService";
import { AnswerWeightage } from "../../customFunctions/AnswerWeightage";
import { CancerOrgan } from "../../customFunctions/CancerOrgan";
import { QuestionAndAnswer } from "../../customFunctions/RiskQuestions";
import AuthService from "../../session/AuthService";
import "./YourRisk.css";

const YourRisk = () => {
  const [questionNumber, SetQuestionNumber] = useState(1);
  const [currentQuestion, SetCurrentQuestion] = useState();
  const [formValues, setFormvalues] = useState({});
  const cardRef = useRef(null);


  const handleForm = (event) => {
    setFormvalues({
      ...formValues,
      [event.target.name]: event.target.value,
    });
  };

  useEffect(() => {
    console.log("FOrmVales", formValues);
  }, [formValues]);

  useEffect(() => {
    SetCurrentQuestion(
      QuestionAndAnswer.filter((item) => item.id === questionNumber)
    );
  }, [questionNumber]);

  useEffect(() => {
    // Apply the animation class when the question changes
    cardRef.current.classList.add("animate-card");

    // Remove the animation class after a brief delay (animation duration)
    const animationTimeout = setTimeout(() => {
      cardRef.current.classList.remove("animate-card");
    }, 500); // Adjust the duration to match your animation

    return () => clearTimeout(animationTimeout);
  }, [currentQuestion]);

  const handleValidateClick = () => {
    console.log("validated::: ", questionNumber, formValues?.gender);

    if (questionNumber < 22) {
      const nextQuestion = getNextQuestion(
        questionNumber,
        formValues?.gender?.toLowerCase()
      );

      console.log("nextQuestion finded:::", nextQuestion);
      SetQuestionNumber(nextQuestion);
    }
  };

  const handleBack = () => {
    if (questionNumber > 1) {
      const previousQuestion = getPreviousQuestion(
        questionNumber,
        formValues?.gender?.toLowerCase()
      );
      SetQuestionNumber(previousQuestion);
      // SetQuestionNumber(questionNumber - 1);
    }
  };

  const handleChange = (val) => {
    console.log("questionNO  :", questionNumber);
    const weightage = AnswerWeightage.filter(
      (item) => item.id === questionNumber
    );

    if (questionNumber === 3) {
      console.log("i am the question 3 condition " + questionNumber);

      const optionNum = parseInt(val.match(/\d+/)) - 1;
  
      setFormvalues({
        ...formValues,
        [currentQuestion[0].id]: val,
        [currentQuestion[0].name]: currentQuestion[0].options[optionNum],
      });
    } else if (questionNumber === 6) {
      setFormvalues({
        ...formValues,
        [currentQuestion[0].id]: val,
      });

      val.forEach((item) => {
        console.log("new console", item);
      });

      console.log("questionNO 6666::::", val, weightage);
    } else {
      setFormvalues({
        ...formValues,
        [currentQuestion[0].id]: val,
      });
    }
  };

  const handleCalculation = async () => {
    const riskRate = {};
    const risk = {
      Breast: 0,
      Ovarian: 0,
      Endometrial: 0,
      Prostate: 0,
      Lung: 0,
      Pancreatic: 0,
      Colorectal: 0,
      Kidney: 0,
      Bladder: 0,
    };

    const { value: formValuesModal } = await Swal.fire({
      title: "Enter your valid contact details",
      html:
        '<label class="email-modal-label" for="swal-input1">Your report is just a click away. Simply enter your details, and we\'ll send the assessment to your email inbox.</label>' +
        '<input id="swal-input1" class="swal2-input my-4" placeholder="Enter your mail ID">' +
        '<input id="swal-input2" class="swal2-input mb-1" placeholder="Enter your phone number">',
      focusConfirm: false,
      preConfirm: () => {
        const mailID = document.getElementById('swal-input1').value;
        const mobile = document.getElementById('swal-input2').value;
        
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        const phoneRegex = /^[0-9]{10,15}$/;
    
        if (!mailID) {
          Swal.showValidationMessage('You need to enter a mail ID!');
          return false;
        }
        if (!emailRegex.test(mailID)) {
          Swal.showValidationMessage('You need to enter a valid mail ID!');
          return false;
        }
        if (!mobile) {
          Swal.showValidationMessage('You need to enter a phone number!');
          return false;
        }
        if (!phoneRegex.test(mobile) && mobile.length > 4) {
          Swal.showValidationMessage('You need to enter a valid phone number!');
          return false;
        }
        
        return { mailID: mailID, mobile: mobile };
      },
      customClass: {
        inputLabel: 'email-modal-label',
      },
      allowOutsideClick: true,
    });

    // const { value: mailID } = await Swal.fire({
    //   customClass: {
    //     inputLabel: 'email-modal-label',
    //   },
    //   allowOutsideClick: false,
    //   title: "Enter your valid contact detail",
    //   input: "text",
    //   inputLabel: "Your report is just a click away. Simply enter your details, and we'll send the assessment to email inbox.",
    //   inputPlaceholder: "Enter your mail ID",
    //   inputValidator: (mailID) => {
    //     if (!mailID) {
    //       return "You need to enter mailID!";
    //     }
    //   },
    // });

    if (formValuesModal.mailID && formValuesModal.mobile) {
      Swal.fire({
        icon: 'success',
        title: 'Your answers will be validated shortly',
        text: 'The report will be sent to the provided email address within 10 minutes.',
      }).then(function () {
        // window.location = "/FullbodyMRI";
        window.location = AuthService.getBaseUrl();
      });

    }


    const heightInMeters = formValues?.height / 100;
    const bmi = parseInt(formValues?.weight / (heightInMeters * heightInMeters));

    console.log("bmi:::::::",bmi);

    if(bmi > 27){
      risk.Breast += 1;
      risk.Endometrial += 2;
      risk.Prostate += 1;
      risk.Colorectal += 2;
      risk.Kindey += 2;
    }

    console.log("risk after bmi::::", risk);

    const selectedOptionsKey6 = formValues[6];

    if (Array.isArray(selectedOptionsKey6)) {
      console.log("selectedOptionsKey6 6thh::::", selectedOptionsKey6);
      for (const selectedOption of selectedOptionsKey6) {
        const weightageData = AnswerWeightage.find((item) => item.id === 6);
        if (selectedOption !== "" && weightageData[selectedOption]) {
          for (const organ in weightageData[selectedOption]) {
            risk[organ] += weightageData[selectedOption][organ];
          }
        }
      }
    }

    // console.log("risk data 6thh::::", risk);

    for (let key = 7; key <= 22; key++) {
      const selectedOption = formValues[key];
      if (selectedOption) {
        const weightageData = AnswerWeightage.find((item) => item.id === key);
        if (selectedOption !== "" && weightageData[selectedOption]) {
          for (const organ in weightageData[selectedOption]) {
            risk[organ] += weightageData[selectedOption][organ];
          }
        }
      }
    }

    for (const organ in risk) {
      const organData = CancerOrgan.find((item) => item.organ === organ);
      if (organData) {
        for (const riskLevel in organData.riskLevels) {
          if (organData.riskLevels[riskLevel].includes(risk[organ])) {
            const capitalizedRiskLevel =
              riskLevel.charAt(0).toUpperCase() + riskLevel.slice(1);
            riskRate[organ.toLowerCase()] = capitalizedRiskLevel + " Risk";
            break;
          }
        }
      }
    }
    riskRate["gender"] = formValues?.gender;
    riskRate["name"] = formValues?.name;
    riskRate["age"] = formValues?.age;
    riskRate["height"] = formValues?.height;
    riskRate["weight"] = formValues?.weight;
    riskRate["mailID"] = formValuesModal.mailID;
    riskRate["mobile"] = formValuesModal.mobile;

    console.log("before api call finalrisk:::::", risk, riskRate);
    if (riskRate) {
      BookingService.getreport(riskRate).then((response) => {
        console.log("response::::", riskRate);
      });
    }

    console.log("finalrisk:::::", risk, riskRate);
  };

  const Dynamicbutton = () => {
    if (questionNumber <= 5 && questionNumber !== 3) {
      return (
        <Button
          variant="primary"
          onClick={handleValidateClick}
          className="button btn-fill question-btn"
          disabled={!formValues[currentQuestion[0]?.name]?.length > 0}
        >
          Continue
        </Button>
      );
    } else if (questionNumber === 22) {
      return (
        <Button
          variant="primary"
          onClick={handleCalculation}
          className="button btn-fill question-btn"
          disabled={!formValues[22]?.length > 0}
          style={{ width: "12rem" }}
        >
          Generate report
        </Button>
      );
    } else {
      return (
        <Button
          variant="primary"
          onClick={handleValidateClick}
          className="button btn-fill question-btn"
          disabled={!formValues[currentQuestion[0]?.id]?.length > 0}
        >
          Continue
        </Button>
      );
    }
  };


  const getNextQuestion = (currentQuestionNumber, usergender) => {
    let nextQuestionNumber = currentQuestionNumber + 1;
    if (usergender) {
      for (let i = nextQuestionNumber; i < QuestionAndAnswer.length; i++) {
        const question = QuestionAndAnswer[i -1];

        if (question.genderBased.toLowerCase() === "both") {
          return question.id;
        }
        if (
          usergender === "male" &&
          question.genderBased.toLowerCase() === "male"
        ) {
          return question.id;
        }
        if (
          usergender === "female" &&
          question.genderBased.toLowerCase() === "female"
        ) {
          return question.id;
        }
      }
    }

    return nextQuestionNumber;
  };

  const getPreviousQuestion = (currentQuestionNumber, usergender) => {
    let previousQuestionNumber = currentQuestionNumber - 1;

    if (usergender) {
      for (let i = previousQuestionNumber; i >= 0; i--) {
        const question = QuestionAndAnswer[i];

        if (question.genderBased === "both") {
          return i;
        }

        if (usergender === "Male" && question.genderBased === "male") {
          return i;
        }

        if (usergender === "Female" && question.genderBased === "female") {
          return i;
        }
      }
    }
    return previousQuestionNumber; // If no specific gender questions found, move to the previous question.
  };

  console.log("current question::::", currentQuestion);

  return (
    <Container fluid>
      <Row className="questions-row">
        <Col ref={cardRef} className="question-col card-animation">
          {currentQuestion ? (
            <div>
              <h3 className="mb-5 text-center">
                {currentQuestion[0].question}
              </h3>
              <div>
                {currentQuestion[0].type === "input" ? (
                  <Form className="d-flex justify-content-center align-items-center">
                    <Form.Group
                      as={Col}
                      lg={6}
                      md={10}
                      controlId={`validationCustom${currentQuestion[0].id}`}
                      className="my-4 riskAssesment"
                    >
                  
                      <Form.Control
                        required
                        type={currentQuestion[0].inputType}
                        placeholder={currentQuestion[0].placeHolder}
                        value={formValues[currentQuestion[0]?.name] ?? ""}
                        onChange={handleForm}
                        name={currentQuestion[0]?.name}
                        onKeyPress={
                          currentQuestion[0]?.inputType === "number"
                            ? (e) => {
                                if (!/[0-9+]/.test(e.key) || e.target.value.length >= 3) {
                                  e.preventDefault();
                                }
                              }
                            : null 
                        }
                      />

                      <Form.Control.Feedback type="invalid">
                        please provide a valid {currentQuestion[0].name}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Form>
                ) : currentQuestion[0].type === "select" ? (
                  <div className="select-col">
                    <div className="mb-3">Please select one</div>
                    <ToggleButtonGroup
                      className="answers-btns"
                      // type="checkbox"
                      type="radio"
                      name={currentQuestion[0].id}
                      value={formValues[currentQuestion[0]?.id] ?? ""}
                      onChange={handleChange}
                    >
                      {currentQuestion[0].options.map((item, index) => (
                        <ToggleButton
                          key={index}
                          variant="outline-info"
                          className="answer-btn col-md-5"
                          value={`option${index + 1}`}
                        >
                          {item}
                        </ToggleButton>
                      ))}
                    </ToggleButtonGroup>
                  </div>
                ) : currentQuestion[0].type === "multiselect" ? (
                  <div className="select-col">
                    <div className="mb-3">Please select</div>
                    <ToggleButtonGroup
                      className="answers-btns"
                      type="checkbox"
                      name={currentQuestion[0]?.name ?? ""}
                      value={
                        formValues[currentQuestion[0]?.name] ??
                        formValues[currentQuestion[0]?.id] ??
                        ""
                      }
                      onChange={handleChange}
                    >
                      {currentQuestion[0].options.map((item, index) => (
                        <ToggleButton
                          key={index}
                          variant="outline-info"
                          className="answer-btn col-md-5"
                          value={`option${index + 1}`}
                        >
                          {item}
                        </ToggleButton>
                      ))}
                    </ToggleButtonGroup>
                  </div>
                ) : (
                  ""
                )}
              </div>

              <Col lg={12} className="butn-cont">
                <Button
                  variant="primary"
                  onClick={handleBack}
                  className="button bg-black mr-4 question-btn"
                >
                  Back
                </Button>

                <Dynamicbutton />
              </Col>
            </div>
          ) : (
            <p>No question found for question number {questionNumber}.</p>
          )}
        </Col>
      </Row>
    </Container>
  );
};

export default YourRisk;
