import { faMapPin } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import AssistantDirectionTwoToneIcon from "@mui/icons-material/AssistantDirectionTwoTone";
import PhoneTwoToneIcon from "@mui/icons-material/PhoneTwoTone";
import React, { useEffect, useState } from "react";
import { Card, Col, Container, Row } from "react-bootstrap";
import BookingService from "../../api/BookingService";
import "./Location.css";

const TemporaryOurLocation = () => {
  const tempararyCities = [
    "Chennai",
    "Ahmedabad",
    "Bangalore",
    "Delhi",
    "Hyderabad",
    "Mumbai",
    "Kolkata",
    "Trivandrum",
    "Pune",
    "Vizag",
  ];

  return (
    <section className="contactUs-wrapper">
      <Container fluid className="my-5 position-relative">
        <div className="map-view"></div>
        <div className=" text-center map-text">
          <h2 className="dm">Our Locations</h2>
          <div>
            <p>Book your Full Body MRI at one of our locations </p>
          </div>
        </div>
      </Container>
      <Container>
        <Row className="justify-content-md-center">
          {tempararyCities.map((item, index) => (
            <Col md={6} sm={6} lg={4} className="my-3" key={index}>
              <Card className="branch_card">
                <Card.Header className="mb-3 mt-2 bg-white branch-subtitle border-0">
                  <FontAwesomeIcon icon={faMapPin} /> &nbsp; City
                </Card.Header>
                <Card.Body className="text-center body-address">
                  <h6>{item}</h6>
                  <Card.Text className="lh-sm">{"96423 96422"}</Card.Text>
                </Card.Body>
                <Card.Footer className="mb-2 mt-3 bg-white d-flex justify-content-evenly ">
                  <Card.Link href="tel:9642396422">
                    <PhoneTwoToneIcon
                      sx={{ color: "#4A89F3" }}
                      color="primary"
                    />
                  </Card.Link>
                </Card.Footer>
              </Card>
            </Col>
          ))}
        </Row>
      </Container>
    </section>
  );
};

export default TemporaryOurLocation;
