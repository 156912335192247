export const CancerOrgan = [
    {
        id: 1,
        organ: "Breast",
        riskLevels: {
            low: [1, 2],
            moderate: [3, 4],
            high: [5, 6, 7, 8]
        }
    },
    {
        id: 2,
        organ: "Ovarian",
        riskLevels: {
            low: [1, 2],
            moderate: [3],
            high: [4, 5, 6]
        }
    },
    {
        id: 3,
        organ: "Endometrial",
        riskLevels: {
            low: [1, 2],
            moderate: [3, 4],
            high: [5, 6, 7]
        }
    },
    {
        id: 4,
        organ: "Prostate",
        riskLevels: {
            low: [1, 2],
            moderate: [3],
            high: [4, 5, 6]
        }
    },
    {
        id: 5,
        organ: "Lung",
        riskLevels: {
            low: [1, 2],
            moderate: [3, 4],
            high: [5, 6, 7]
        }
    },
    {
        id: 6,
        organ: "Pancreatic",
        riskLevels: {
            low: [1, 2, 3],
            moderate: [4, 5, 6],
            high: [7, 8, 9, 10, 11]
        }
    },
    {
        id: 7,
        organ: "Colorectal",
        riskLevels: {
            low: [1, 2, 3],
            moderate: [4, 5],
            high: [6, 7, 8, 9, 10]
        }
    },
    {
        id: 8,
        organ: "Kidney",
        riskLevels: {
            low: [1, 2],
            moderate: [3, 4],
            high: [5, 6, 7]
        }
    },
    {
        id: 9,
        organ: "Bladder",
        riskLevels: {
            low: [1, 2],
            moderate: [3, 4],
            high: [5, 6, 7]
        }
    }
];
