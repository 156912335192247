import React from "react";
import {
  BrowserRouter as Router,
} from "react-router-dom";
import Footer from "../screens/Footer/Footer.jsx";
import Header from "../screens/Header/Header.jsx";
import AuthService from "../session/AuthService.js";
import NavigationRouter from "./NavigationRouter.jsx";

export function Navigations() {
   
    if(AuthService.getBaseUrl() === ""){
      if(window.location.host === "artest.betalearnings.com"){
        AuthService.setBaseUrl(`/vitalmri/`);
      }else{
        AuthService.setBaseUrl(`/`);
      }
    }
    return (
      
      <Router basename={AuthService.getBaseUrl()}>
          <Header/> 
          <NavigationRouter />
          <Footer />
      </Router>
      
    );
  
}
export default Navigations;


