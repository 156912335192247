import * as appConfig from "../config/app-config";
import api from "./BaseAPI.js";

class BookingService {
  postBooking(formValues) {
    console.log("url:::::::::::", appConfig.API_URL);
    return api({
      method: "post",
      url: `${appConfig.API_URL}auth/booknewappoinment`,
      data: JSON.stringify(formValues),
    });
  }

  getmrilocation() {
    return api({
      method: "post",
      url: `${appConfig.API_URL}auth/getmrilocation`,
      data: JSON.stringify({}),
    });
  }

  createRazorPayOrder(form) {
    return api({
      method: "post",
      url: `${appConfig.API_URL}razorPay/createRazorPayOrder`,
      data: JSON.stringify(form),
    });
  }

  paymentStatus(form){

    return api({
      method: "post",
      url: `${appConfig.API_URL}razorPay/updateRazorPayOrder`,
      data: JSON.stringify(form),
    });
  }
  getCallBack(form) {
    return api({
      method: "post",
      url: `${appConfig.API_URL}auth/getCall`,
      data: JSON.stringify(form),
    });
  }

  getreport(form) {
    return api({
      method: "post",
      url: `${appConfig.API_URL}auth/generatereport`,
      data: JSON.stringify(form),
    });
  }

}

export default new BookingService();
