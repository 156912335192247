export const OrganDescription = [
  {
    organ_id: 1,
    organ_title: "CNS PNET",
   organ_description:""
  },
  {
    organ_id: 1,
    organ_title: "Pineal cyst",
   organ_description:""
  },
  {
    organ_id: 1,
    organ_title: "Pineal germinoma",
    organ_description:""
  },
  {
    organ_id: 1,
    organ_title: "Pineocytoma",
 organ_description:""
  },
  {
    organ_id: 1,
    organ_title: "Pineoblastoma",
    organ_description:""
     
  },
  {
    organ_id: 1,
    organ_title: "Pineal inclusion cyst",
   organ_description: ""
  },
  {
    organ_id: 1,
    organ_title: "Papillary tumor of pineal origin",
  organ_description: ""
  },
  {
    organ_id: 1,
    organ_title: "Cerebral palsy",
      organ_description: ""
  },
  {
    organ_id: 1,
    organ_title: "Chiari malformation Type I ",
     organ_description:""
  },
  {
    organ_id: 1,
    organ_title: "Chiari malformation Type II ",
    organ_description: ""
  },
  {
    organ_id: 1,
    organ_title: "Chiari malformation Type III ",
     organ_description: ""
  },
  {
    order_id: 1,
    organ_title: "Chiari malformation Type IV ",
   organ_description: ""
  },
  {
    organ_id: 1,
    organ_title: "Dementia",
    organ_description: ""
  },
  {
    organ_id: 1,
    organ_title: "Encephalitis",
    organ_description: ""
  },
  {
    organ_id: 1,
    organ_title: "Hydrocephalus",
    organ_description: ""
  },
  {
    organ_id: 1,
    organ_title: "Huntington's disease",
    organ_description: ""
  
  },
  {
    organ_id: 1,
    organ_title: "Intracranial hemorrhage",
     organ_description: ""
  },
  {
    organ_id: 1,
    organ_title: "Meningitis",
    organ_description: ""
  },
  {
    organ_id: 1,
    organ_title: "Multiple sclerosis (MS)",
   organ_description: ""
  },
  {
    organ_id: 1,
    organ_title: "Myelomeningocele",
     organ_description: ""
    },
  {
    organ_id: 1,
    organ_title: "Empty sella syndrome ",
     organ_description: ""
  
  },
  {
    organ_id: 1,
    organ_title: "Pituitary macroadenoma",
     organ_description: ""
  
  },
  {
    organ_id: 1,
    organ_title: "Absent posterior pituitary gland",
     organ_description: ""
   
  },
  {
    organ_id: 1,
    organ_title: "Ectopic posterior pituitary gland",
     organ_description: ""
   
  },
  {
    organ_id: 1,
    organ_title: "Pituitary carcinoma",
     organ_description: ""
  
  },
  {
    organ_id: 1,
    organ_title: "Pituitary blastoma",
     organ_description: ""
    
  },
  {
    organ_id: 1,
    organ_title: "Germinoma",
     organ_description: ""
  
  },
  {
    organ_id: 1,
    organ_title: "Dermoid",
    organ_description:"",
  },
  {
    organ_id: 1,
    organ_title: "Pituitary metastasis",
    organ_description:"",
  },
  {
    organ_id: 1,
    organ_title: "Hamartoma of Tuber cinerum",
    organ_description:
      "",
  },
  {
    organ_id: 1,
    organ_title: "Neurosarcoidosis",
    organ_description:
      "",
  },
  {
    organ_id: 1,
    organ_title: "Intracranial lipoma",
    organ_description:
      "",
  },
  {
    organ_id: 1,
    organ_title: "Lymphocytic hypophysitis",
    organ_description:
      "",
  },
  {
    organ_id: 1,
    organ_title: "Rathke's cleft cyst",
    organ_description:
      "",
  },
  {
    organ_id: 1,
    organ_title: "Carnipharyngioma",
    organ_description:
      "",
  },
  {
    organ_id: 1,
    organ_title: "Hypothalamic Glioma",
    organ_description:
      "",
  },
  {
    organ_id: 1,
    organ_title: "Stroke",
    organ_description:
      "",
  },
  {
    organ_id: 1,
    organ_title: "Traumatic brain injury (TBI)",
    organ_description:
      "",
  },
  {
    organ_id: 1,
    organ_title: "White matter disease",
    organ_description:
      "",
  },
  
  

  
  {
    organ_id: 2,
    organ_title: "Optic nerve Meningioma",
    organ_description:
      "",
  },
  {
    organ_id: 2,
    organ_title: "Orbital Schwannoma",
    organ_description:
      "",
  },
  {
    organ_id: 2,
    organ_title: "Orbital Lymphoma",
    organ_description:
      "",
  },
  {
    organ_id: 2,
    organ_title: "Orbital Metastasis",
    organ_description:
      "",
  },
  {
    organ_id: 2,
    organ_title: "Retinoblastoma",
    organ_description:
      "",
  },
  {
    organ_id: 2,
    organ_title: "Optic nerve Glioma",
    organ_description:
      "",
  },
  {
    organ_id: 2,
    organ_title: "Orbital pseudotumor",
    organ_description:
      "",
  },
  {
    organ_id: 2,
    organ_title: "Thyroid eye disease",
    organ_description:
      "",
  },
  {
    organ_id: 2,
    organ_title: "Orbital Sarcoidosis",
    organ_description:
      "",
  },
  {
    organ_id: 2,
    organ_title: "Wegener's granulomatosis",
    organ_description:
      "",
  },
  {
    organ_id: 2,
    organ_title: "Orbital Abscess",
    organ_description:
      "",
  },
  {
    organ_id: 2,
    organ_title: "Orbital Cellulitis",
    organ_description:
      "",
  },
  {
    organ_id: 2,
    organ_title: "Orbital venous varix",
    organ_description:
      "",
  },
  {
    organ_id: 2,
    organ_title: "Cavernous sinus thrombosis",
    organ_description:
      "",
  },
  {
    organ_id: 2,
    organ_title: "Optic nerve coloboma",
    organ_description:
      "",
  },
  {
    organ_id: 5,
    organ_title: "Orbital Encephalocele",
    organ_description:
      "",
  },
  {
    organ_id: 2,
    organ_title: "Graves' disease",
    organ_description:
      "",
  },
  {
    organ_id: 2,
    organ_title: "Optic nerve atrophy",
    organ_description:
      "",
  },
  {
    organ_id: 2,
    organ_title: "Papilledema",
    organ_description:
      "",
  },
  {
    organ_id: 2,
    organ_title: "Globe rupture",
    organ_description:
      "",
  },
  {
    organ_id: 2,
    organ_title: "Pthysis bulbi",
    organ_description:
      "",
  },
  {
    organ_id: 2,
    organ_title: "Rhabdomyosarcoma of orbit",
    organ_description:
      "",
  },
  {
    organ_id: 2,
    organ_title: "Cavernous hemangioma of orbit",
    organ_description:
      "",
  },
  {
    organ_id: 2,
    organ_title: "Orbital venous varix",
    organ_description:
      "",
  },
  {
    organ_id: 2,
    organ_title: "Lacrimal Gland adenoid cystic carcinoma",
    organ_description:
      "",
  },
  {
    organ_id: 2,
    organ_title: "Lacrimal gland adenocarcinoma",
    organ_description:
      "",
  },
  {
    organ_id: 2,
    organ_title: "Lacrimal gland lymphoma",
    organ_description:
      "",
  },
  
  {
    organ_id: 3,
    organ_title: "Acute sinusitis",
    organ_description:
      "",
  },
  {
    organ_id: 3,
    organ_title: "Chronic sinusitis",
    organ_description:
      "",
  },

  {
    organ_id: 3,
    organ_title: "Fungal sinusitis",
    organ_description:
      "",
  },
  {
    organ_id: 3,
    organ_title: "Granulomatous sinusitis",
    organ_description:
      "",
  },{
    organ_id: 3,
    organ_title: "Papillomas",
    organ_description:
      "",
  },
  {
    organ_id: 3,
    organ_title: "Mucoceles",
    organ_description:
      "",
  },
  {
    organ_id: 3,
    organ_title: "Nasal polyps",
    organ_description:
      "",
  },
  {
    organ_id: 3,
    organ_title: "Foreign bodies",
    organ_description:
      "",
  },
  {
    organ_id: 3,
    organ_title: "Malignant tumors of PNS",
    organ_description:
      "",
  },

  {
    organ_id: 4,
    organ_title: "Squamous cell carcinoma of the tongue",
    organ_description:
      "",
  },
  {
    organ_id: 4,
    organ_title: "Squamous cell carcinoma on floor of the mouth",
    organ_description:
      "",
  },
  {
    organ_id: 4,
    organ_title: "Squamous cell carcinoma of buccal mucosa",
    organ_description:
      "",
  },
  {
    organ_id: 4,
    organ_title: "Squamous cell carcinoma of palate",
    organ_description:
      "",
  },
  {
    organ_id: 4,
    organ_title: "Squamous cell carcinoma of gingiva",
    organ_description:
      "",
  },
  {
    organ_id: 4,
    organ_title: "Squamous cell carcinoma of lips",
    organ_description:
      "",
  },
  {
    organ_id: 4,
    organ_title: "Pleomorphic adenoma",
    organ_description:
      "",
  },
  {
    organ_id: 4,
    organ_title: "mucoepidermoid carcinoma",
    organ_description:
      "",
  },
  {
    organ_id: 4,
    organ_title: "acinic cell carcinoma",
    organ_description:
      "",
  },
  {
    organ_id: 4,
    organ_title: "ameloblastoma",
    organ_description:
      "",
  },
  {
    organ_id: 4,
    organ_title: "odontoma",
    organ_description:
      "",
  },
  {
    organ_id: 4,
    organ_title: "odontogenic fibroma",
    organ_description:
      "",
  },
  {
    organ_id: 4,
    organ_title: "Abscesses of the oral cavity",
    organ_description:
      "",
  },
  {
    organ_id: 4,
    organ_title: "Osteomyelitis of the mandible",
    organ_description:
      "",
  },
  {
    organ_id: 4,
    organ_title: "Osteomyelitis of the maxilla",
    organ_description:
      "",
  },
  {
    organ_id: 4,
    organ_title: "Dislocations of the temporomandibular joint",
    organ_description:
      "",
  },
  {
    organ_id: 4,
    organ_title: "Degenerative arthropathy of TMJ",
    organ_description:
      "",
  },

  {
    organ_id: 5,
    organ_title: "Oncocytoma",
    organ_description:
      "",
  },
  {
    organ_id: 5,
    organ_title: "Warthin tumor",
    organ_description:
      "",
  },
  {
    organ_id: 5,
    organ_title: "Acinic cell carcinoma ",
    organ_description:
      "",
  },
  {
    organ_id: 5,
    organ_title: "Adenocarcinoma",
    organ_description:
      "",
  },
  {
    organ_id: 5,
    organ_title: "Adenoid cystic carcinoma ",
    organ_description:
      "",
  },
  {
    organ_id: 5,
    organ_title: "Clear cell carcinoma ",
    organ_description:
      "",
  },
  {
    organ_id: 5,
    organ_title: "Malignant mixed tumor ",
    organ_description:
      "",
  },
  {
    organ_id: 5,
    organ_title: "Mucoepidermoid carcinoma ",
    organ_description:
      "",
  },
  {
    organ_id: 5,
    organ_title: "Oncocytic carcinoma ",
    organ_description:
      "",
  },
  {
    organ_id: 5,
    organ_title: "Polymorphous low-grade adenocarcinoma ",
    organ_description:
      "",
  },
  {
    organ_id: 5,
    organ_title: "Salivary duct carcinoma ",
    organ_description:
      "",
  },
  {
    organ_id: 5,
    organ_title: "Salivary gland Lymphomas",
    organ_description:
      "",
  },
  {
    organ_id: 5,
    organ_title: "Neck abscesses",
    organ_description:
      "",
  },
 
  {
    organ_id: 5,
    organ_title: "Retropharyngeal abscesses",
    organ_description:
      "",
  },
  {
    organ_id: 5,
    organ_title: "Cervical lymphadenitis",
    organ_description:
      "",
  },
  {
    organ_id: 5,
    organ_title: "Carotid artery stenosis",
    organ_description:
      "",
  },
  {
    organ_id: 5,
    organ_title: "Vertebral artery stenosis",
    organ_description:
      "",
  },
  {
    organ_id: 5,
    organ_title: "Carotid body tumors",
    organ_description: "",
  },
  {
    organ_id: 5,
    organ_title: "Vocal cord Squamous Cell Carcinoma ",
    organ_description:
      "",
  },
  {
    organ_id: 5,
    organ_title: "Adenocarcinoma of vocal cord",
    organ_description:
      "",
  },

  {
    organ_id: 5,
    organ_title: "Adenoid Cystic Carcinoma of vocal cors",
    organ_description:
      "",
  },

  {
    organ_id: 5,
    organ_title: "Vocal cord Lymphoepithelioma ",
    organ_description:
      "",
  },

  {
    organ_id: 5,
    organ_title: "Vocal cord Spindle cell carcinoma",
    organ_description:
      "",
  },

  {
    organ_id: 5,
    organ_title: "Vocal cord cysts ",
    organ_description:
      "",
  },

  {
    organ_id: 5,
    organ_title: "Vocal cord large polyps ",
    organ_description:
      "",
  },
  {
    organ_id: 5,
    organ_title: "Vocal cord Sarcoma ",
    organ_description:
      "",
  },

  {
    organ_id: 5,
    organ_title: "Squamous cell carcinoma of pharynx",
    organ_description:
      "",
  },

  {
    organ_id: 5,
    organ_title: "Adenocarcinoma of pharynx",
    organ_description:
      "",
  },

  {
    organ_id: 5,
    organ_title:
      "Lymphoepithelioma-like carcinoma",
    organ_description:
      "",
  },

  {
    organ_id: 5,
    organ_title: "Minor salivary gland tumors",
    organ_description:
      "",
  },

  {
    organ_id: 5,
    organ_title: "Thyroid gland enlargement",
    organ_description:
      "",
  },

  {
    organ_id:5,
    organ_title: "Laryngeal Cancer",
    organ_description:
      "",
  },
  {
    organ_id: 5,
    organ_title: "Laryngeal Papillomatosis",
    organ_description:
      "",
  },
  {
    organ_id: 5,
    organ_title: "Laryngeal Cysts",
    organ_description:
      "",
  },
  {
    organ_id: 5,
    organ_title: "Laryngeal Stenosis",
    organ_description:
      "",
  },
  {
    organ_id: 5,
    organ_title: "Laryngeal Nodules and Polyps",
    organ_description:
      "",
  },
  
  {
    organ_id: 6,
    organ_title: "Pericardial effusion",
    organ_description:
      "",
  },
  {
    organ_id: 6,
    organ_title: "Aortic aneurysm",
    organ_description:
      "",
  },
  {
    organ_id: 6,
    organ_title: "Small cell lung cancer",
    organ_description:
      "",
  },

  {
    organ_id: 6,
    organ_title: "Squamous cell lung cancer",
    organ_description:
      "",
  },
  {
    organ_id: 6,
    organ_title: "Bronchioalveolar lung cancer",
    organ_description:
      "",
  },

  {
    organ_id: 6,
    organ_title: "Adenocarcinoma of lung",
    organ_description:
      "",
  },
  {
    organ_id: 6,
    organ_title: "Pneumonia",
    organ_description:
      "",
  },
  {
    organ_id: 6,
    organ_title: "Interstitial lung diseases",
    organ_description:
      "",
  },
  {
    organ_id: 6,
    organ_title: "sarcoidosis",
    organ_description:
      "",
  },
  {
    organ_id: 6,
    organ_title: "Pulmonary abscess",
    organ_description:
      "",
  },
  {
    organ_id: 6,
    organ_title: "Thymoma",
    organ_description:
      "",
  },
  {
    organ_id: 13,
    organ_title: "Thymic carcinoma ",
    organ_description:
      "",
  },
  {
    organ_id: 6,
    organ_title: "Thymic cysts",
    organ_description:
      "",
  },
  {
    organ_id: 6,
    organ_title: "Lymphoma",
    organ_description:
      "",
  },
  {
    organ_id: 6,
    organ_title: "Bronchogenic cyst ",
    organ_description:
      "",
  },

  {
    organ_id: 6,
    organ_title: "Mediastinal lymphadenopathy ",
    organ_description:
      "",
  },

  {
    organ_id: 6,
    organ_title: "Bone cyst of the knee",
    organ_description:
      "",
  },

  {
    organ_id: 6,
    organ_title: "Esophageal tumors ",
    organ_description:
      "",
  },

  {
    organ_id: 6,
    organ_title: "Tracheal tumours ",
    organ_description:
      "",
  },

  {
    organ_id: 6,
    organ_title: "Pericardial cyst ",
    organ_description:
      "",
  },

  {
    organ_id: 6,
    organ_title: "Nerve sheath tumors ",
    organ_description:
      "",
  },

  {
    organ_id: 6,
    organ_title: "Ganglion cell tumors ",
    organ_description:
      "",
  },

  {
    organ_id: 6,
    organ_title: "Paragangliomas",
    organ_description:
      "",
  },

  {
    organ_id: 6,
    organ_title: "Mediastinal lymphadenopathy ",
    organ_description:
      "",
  },

  {
    organ_id: 6,
    organ_title: "Extramedullary hematopoiesis (EMH) ",
    organ_description:
      "",
  },

  {
    organ_id: 6,
    organ_title: "Neuroenteric cyst ",
    organ_description:
      "",
  },

  {
    organ_id: 6,
    organ_title: "Pleural effusion",
    organ_description:
      "",
  },

  {
    organ_id: 6,
    organ_title: "Diaphragmatic hernia",
    organ_description:
      "",
  },

  {
    organ_id: 6,
    organ_title: "Tuberculosis",
    organ_description:
      "",
  },

  {
    organ_id: 7,
    organ_title: "Inflammatory bowel disease (Crohn's disease and ulcerative colitis)",
    organ_description:
      "",
  },

  {
    organ_id: 7,
    organ_title: "Retroperitoneal Lipomas ",
    organ_description:
      "",
  },

  {
    organ_id: 7,
    organ_title: "Retroperitoneal Fibromas ",
    organ_description:
      "",
  },

  {
    organ_id: 7,
    organ_title: "Retroperitoneal Leiomyomas ",
    organ_description:
      "",
  },

  {
    organ_id: 7,
    organ_title: "Retroperitoneal Schwannomas ",
    organ_description:
      "",
  },

  {
    organ_id: 7,
    organ_title: "Retroperitoneal fibrosis ",
    organ_description:
      "",
  },

  {
    organ_id: 7,
    organ_title: "Retroperitoneal Abscesses ",
    organ_description:
      "",
  },

  {
    organ_id: 7,
    organ_title: "Retroperitoneal Hematomas ",
    organ_description:
      "",
  },

  {
    organ_id: 7,
    organ_title: "Lymphatic cysts ",
    organ_description:
      "",
  },

  {
    organ_id: 7,
    organ_title: "Mesenteric cysts",
    organ_description:
      "",
  },

  {
    organ_id: 7,
    organ_title: "Vascular Retroperitoneal Masses ",
    organ_description:
      "",
  },

  {
    organ_id: 7,
    organ_title: "Desmoplastic small round cell tumor ",
    organ_description:
      "",
  },

  {
    organ_id: 7,
    organ_title: "Retroperitoneal Teratoma ",
    organ_description:
      "",
  },

  {
    organ_id: 7,
    organ_title: "Retroperitoneal  Sarcoma",
    organ_description:
      "",
  },

  {
    organ_id: 7,
    organ_title: "Retroperitoneal Metastases ",
    organ_description:
      "",
  },

  {
    organ_id: 7,
    organ_title: "Absent kidney",
    organ_description:
      "",
  },

  {
    organ_id: 7,
    organ_title: "Renal angiomyolipoma",
    organ_description:
      "",
  },

  {
    organ_id: 7,
    organ_title: "Renal oncocytoma",
    organ_description:
      "",
  },

  {
    organ_id: 7,
    organ_title: "Renal metastasis",
    organ_description:
      "",
  },

  {
    organ_id: 7,
    organ_title: "Renal lymphoma",
    organ_description:
      "",
  },

  {
    organ_id: 7,
    organ_title: "Renal cell carcinoma",
    organ_description:
      "",
  },

  {
    organ_id: 7,
    organ_title: "Transitional cell carcinoma",
    organ_description:
      "",
  },

  {
    organ_id: 7,
    organ_title: "Renal abscess",
    organ_description:
      "",
  },

  {
    organ_id: 7,
    organ_title: "Renal hematoma",
    organ_description:
      "",
  },

  {
    organ_id: 7,
    organ_title: "Perinephric abscess",
    organ_description:
      "",
  },

  {
    organ_id:7,
    organ_title: "Xanthogranulomatous pyelonephritis",
    organ_description:
      "",
  },

  {
    organ_id: 7,
    organ_title: "Hydronephrosis",
    organ_description:
      "",
  },

  {
    organ_id: 7,
    organ_title: "Empysematous pyelonephritis",
    organ_description:
      "",
  },

  {
    organ_id: 7,
    organ_title: "Multilocular cystic renal neoplasm",
    organ_description:
      "",
  },

  {
    organ_id: 7,
    organ_title: "Renal medullary carcinoma",
    organ_description:
      "",
  },

  {
    organ_id: 7,
    organ_title: "Renal nephroblastoma",
    organ_description:
      "",
  },

  {
    organ_id: 7,
    organ_title: "Cystic nephroma",
    organ_description:
      "",
  },
  


  {
    organ_id: 8,
    organ_title: "Clear cell Carcinoma of vagina",
    organ_description:
      "",
  },

  {
    organ_id: 8,
    organ_title: "Vulvar cancer",
    organ_description:
      "",
  },
  {
    organ_id: 8,
    organ_title: "Prostate cancer                                                                                 ",
    organ_description:
      "",
  },

  {
    organ_id: 8,
    organ_title: "Germ cell tumor of testes",
    organ_description:
      "",
  },

  {
    organ_id: 8,
    organ_title: "Seminoma",
    organ_description:
      "",
  },

  {
    organ_id: 8,
    organ_title: "Nonseminomatous germ cell tumors",
    organ_description:
      "",
  },

  {
    organ_id: 8,
    organ_title: "Embryonal carcinoma ",
    organ_description:
      "",
  },

  {
    organ_id: 8,
    organ_title: "Teratoma",
    organ_description:
      "",
  },

  {
    organ_id: 8,
    organ_title: "Yolk sac carcinoma ",
    organ_description:
      "",
  },

  {
    organ_id: 8,
    organ_title: "Choriocarcinoma",
    organ_description:
      "",
  },

  {
    organ_id: 8,
    organ_title: "Mixed germ cell tumors ",
    organ_description:
      "",
  },

  {
    organ_id:8,
    organ_title: "Leydig cell tumors ",
    organ_description:
      "",
  },

  {
    organ_id: 8,
    organ_title: "Sertoli cell tumors ",
    organ_description:
      "",
  },

  {
    organ_id: 8,
    organ_title: "Inguinal hernia",
    organ_description:
      "",
  },

  {
    organ_id: 8,
    organ_title: "Urothelial carcinoma of bladder",
    organ_description:
      "",
  },

  {
    organ_id: 8,
    organ_title: "Small cell carcinoma of the bladder",
    organ_description:
      "",
  },

  {
    organ_id: 8,
    organ_title: "Squamous cell carcinoma of bladder",
    organ_description:
      "",
  },

  {
    organ_id: 8,
    organ_title: "Adenocarcinoma of bladder  ",
    organ_description:
      "",
  },

  {
    organ_id: 8,
    organ_title: "Ureteral cancer",
    organ_description:
      "",
  },

  {
    organ_id: 8,
    organ_title: "Cystitis",
    organ_description:
      "",
  },

  {
    organ_id: 8,
    organ_title: "Bladder diverticulum",
    organ_description:
      "",
  },

  {
    organ_id: 8,
    organ_title: "Gastrointestinal Stromal Tumors (GISTs) ",
    organ_description:
      "",
  },

  {
    organ_id: 8,
    organ_title: "Adenocarcinoma of bowel",
    organ_description:
      "",
  },

  {
    organ_id: 8,
    organ_title: "Carcinoid Tumors ",
    organ_description:
      "",
  },

  {
    organ_id: 8,
    organ_title: "Lymphoma of bowel",
    organ_description:
      "",
  },

  {
    organ_id: 8,
    organ_title: "Sarcoma of bowel",
    organ_description:
      "",
  },

  {
    organ_id: 8,
    organ_title: "Crohn's disease",
    organ_description:
      "",
  },

  {
    organ_id: 8,
    organ_title: "Diverticulitis",
    organ_description:
      "",
  },

  {
    organ_id: 8,
    organ_title: "Ulcerative colitis",
    organ_description:
      "",
  },
  {
    organ_id: 9,
    organ_title: "Avascular necrosis (AVN) of the femoral head",
    organ_description:
      "",
  },

  {
    organ_id: 9,
    organ_title: "Transient osteoporosis of the hip (TOH)",
    organ_description:
      "",
  },

  {
    organ_id: 9,
    organ_title: "Stress fractures of the hip and femur",
    organ_description:
      "",
  },

  {
    organ_id: 9,
    organ_title: "Osteomyelitis of the hip and femur",
    organ_description:
      "",
  },

  {
    organ_id: 9,
    organ_title: "Septic arthritis of the hip",
    organ_description:
      "",
  },

  {
    organ_id: 9,
    organ_title: "Synovitis of the hip",
    organ_description:
      "",
  },

  {
    organ_id: 9,
    organ_title: "Legg-Calvé-Perthes disease",
    organ_description:
      "",
  },

  {
    organ_id: 9,
    organ_title: "Hip bursitis",
    organ_description:
      "",
  },

  {
    organ_id: 9,
    organ_title: "Tendonitis of gluteus tendons",
    organ_description:
      "",
  },

  {
    organ_id: 9,
    organ_title: "Metastases to the hip and femur",
    organ_description:
      "",
  },

  {
    organ_id: 9,
    organ_title: "Infections of the hip",
    organ_description:
      "",
  },

  {
    organ_id: 10,
    organ_title: "Paget's disease",
    organ_description:
      "",
  },

  {
    organ_id: 10,
    organ_title: "Charcot's arthropathy",
    organ_description:
      "",
  },

  {
    organ_id: 10,
    organ_title: "Knee joint effusion",
    organ_description:
      "",
  },

  {
    organ_id: 10,
    organ_title: "Ankle joint effusion",
    organ_description:
      "",
  },

  {
    organ_id: 10,
    organ_title: "Hip joint effusion",
    organ_description:
      "",
  },

  {
    organ_id: 10,
    organ_title: "Shoulder joint effusion",
    organ_description:
      "",
  },

  {
    organ_id: 10,
    organ_title: "Bursitis",
    organ_description:
      "",
  },
  {
    organ_id: 10,
    organ_title: "Inflammatory Arthritis",
    organ_description:
      "",
  },

  {
    organ_id: 10,
    organ_title: "Joint infections",
    organ_description:
      "",
  },

  {
    organ_id: 10,
    organ_title: "Varicose veins",
    organ_description:
      "",
  },

  {
    organ_id: 10,
    organ_title: "Large Peripheral artery aneurysms",
    organ_description:
      "",
  },

  {
    organ_id: 10,
    organ_title: "Myositis",
    organ_description:
      "",
  },

  {
    organ_id: 10,
    organ_title: "Muscular dystrophy",
    organ_description:
      "",
  },

  {
    organ_id: 10,
    organ_title: "Rhabdomyolysis",
    organ_description:
      "",
  },

  {
    organ_id: 10,
    organ_title: "Lipoma",
    organ_description:
      "",
  },

  {
    organ_id: 10,
    organ_title: "Myxoma",
    organ_description:
      "",
  },

  {
    organ_id: 10,
    organ_title: "Fibrosarcoma",
    organ_description:
      "",
  },

  {
    organ_id: 10,
    organ_title: "Angiomylipoma",
    organ_description:
      "",
  },

  {
    organ_id: 10,
    organ_title: "Epithelioid sarcoma ",
    organ_description:
      "",
  },

  {
    organ_id: 10,
    organ_title: "Clear cell sarcoma ",
    organ_description:
      "",
  },

  {
    organ_id: 10,
    organ_title: "Extra skeletal myxoid chondrosarcoma ",
    organ_description:
      "",
  },

  {
    organ_id: 10,
    organ_title: "Desmoplastic small round cell tumor ",
    organ_description:
      "",
  },

  {
    organ_id: 10,
    organ_title: "Rhabdoid tumor ",
    organ_description:
      "",
  },

  {
    organ_id: 10,
    organ_title: "Perivascular epithelioid tumor ",
    organ_description:
      "",
  },

  {
    organ_id:10,
    organ_title: "Muscle infections",
    organ_description:
      "",
  },

  {
    organ_id: 10,
    organ_title: "Muscle atrophy",
    organ_description:
      "",
  },

  {
    organ_id: 10,
    organ_title: "Abscess",
    organ_description:
      "",
  },

  {
    organ_id: 10,
    organ_title: "Ganglion cyst",
    organ_description:
      "",
  },

  {
    organ_id: 10,
    organ_title: "Hematoma",
    organ_description:
      "",
  },

  {
    organ_id: 11,
    organ_title: "cervical Disc herniation",
    organ_description:
      "",
  },

  {
    organ_id: 11,
    organ_title: "cervical Spinal canal stenosis",
    organ_description:
      "",
  },

  {
    organ_id: 11,
    organ_title: "cervical Facet joint arthropathy",
    organ_description:
      "",
  },

  {
    organ_id: 11,
    organ_title: "cervical Scoliosis",
    organ_description:
      "",
  },

  {
    organ_id: 12,
    organ_title: "dorsal Disc herniation",
    organ_description:
      "",
  },

  {
    organ_id: 12,
    organ_title: "dorsal Spinal canal stenosis",
    organ_description:
      "",
  },

  {
    organ_id: 12,
    organ_title: "dorsal Facet joint arthropathy",
    organ_description:
      "",
  },

  {
    organ_id: 12,
    organ_title: "dorsal Scoliosis",
    organ_description:
      "",
  },
  {
    organ_id: 13,
    organ_title: "Degenerative diseases",
    organ_description:
      "",
  },

  {
    organ_id: 13,
    organ_title: "Lumbar Disc herniation",
    organ_description:
      "",
  },

  {
    organ_id: 13,
    organ_title: "Lumbar Spinal canal stenosis",
    organ_description:
      "",
  },

  {
    organ_id: 13,
    organ_title: "Lumbar Facet joint arthropathy",
    organ_description:
      "",
  },

  {
    organ_id: 24,
    organ_title: "lumbar Scoliosis",
    organ_description:
      "",
  },

  {
    organ_id: 24,
    organ_title: "Congenital abnormalities",
    organ_description:
      "",
  },

  {
    organ_id: 24,
    organ_title: "Syringomyelia",
    organ_description:
      "",
  },

  {
    organ_id: 24,
    organ_title: "Chiari malformations",
    organ_description:
      "",
  },

  {
    organ_id: 24,
    organ_title: "Demyelination in spinal cord",
    organ_description:
      "",
  },

  {
    organ_id: 25,
    organ_title: "Ankylosing spondylitis",
    organ_description:
      "",
  },

  {
    organ_id: 25,
    organ_title: "Rheumatoid arthritis",
    organ_description:
      "",
  },

  {
    organ_id: 25,
    organ_title: "Arteriovenous malformations",
    organ_description:
      "",
  },

  {
    organ_id: 25,
    organ_title: "Spinal cord infarction",
    organ_description:
      "",
  },

  {
    organ_id: 25,
    organ_title: "Spinal cord hemorrhage",
    organ_description:
      "",
  },

  {
    organ_id: 25,
    organ_title: "Spinal cord injury",
    organ_description:
      "",
  },

  {
    organ_id: 25,
    organ_title: "Vertebral fractures",
    organ_description:
      "",
  },

  {
    organ_id: 25,
    organ_title: "Synovial cysts",
    organ_description:
      "",
  },

  {
    organ_id: 25,
    organ_title: "Tarlov cysts",
    organ_description:
      "",
  },

  {
    organ_id: 25,
    organ_title: "Eosinophilic granuloma",
    organ_description:
      "",
  },

  {
    organ_id: 25,
    organ_title: "Paget's disease",
    organ_description:
      "",
  },

  {
    organ_id: 26,
    organ_title: "Degenerative spondylolisthesis",
    organ_description:
      "",
  },

  {
    organ_id: 26,
    organ_title: "Spondylolysis",
    organ_description:
      "",
  },
];
export default OrganDescription;
    