import { IsExists } from "../customFunctions/Custom";

class AuthService {
  
  selectedMRI(data){
    sessionStorage.setItem("packageMRI", JSON.stringify(data));
  }

  paymentStatus(data){
    sessionStorage.setItem("paymentStatus", JSON.stringify(data));
  }

  getpaymentStatus(){
    let data = sessionStorage.getItem("paymentStatus");
    // data = IsExists(data)? JSON.parse(data) :'';
    return data;
  }


  getSelectedMRI(){
   let data = sessionStorage.getItem("packageMRI");
      data = IsExists(data)? JSON.parse(data) :'';
    return data;
  }

  setBaseUrl(data){
    sessionStorage.setItem("baseurl", JSON.stringify(data));
  }

  getBaseUrl(){
    let data = sessionStorage.getItem("baseurl");
    data = IsExists(data)? JSON.parse(data) :'';
    return data;
  }

}

export default new AuthService();
