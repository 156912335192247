import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import './AppointmentBooked.css';
import tick from "../../../images/greentick.svg"
import { Link } from 'react-router-dom';

const AppointmentBooked = () => {
    return (
        <section className="achivement-wrap">
            <Container>
                
                <Row className="align-items-center">
                              <img src={tick} alt="successs" width="180" height="130"/>  
                          <Col className='conf-header'> <h4>Your Appointment has been confirmed! </h4></Col>
                </Row>
                <Row className='conf-bottom'>
                         <Col className=''> Our team will be in contact with you shortly... </Col>
                         <Link className="text-decort" to="/"><button className="btn btn-warning p-3 fw-bold">Go Back</button></Link>
                </Row>
            </Container>
        </section>
    );
};

export default AppointmentBooked;