import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import logo from "../../images/logo.png";
import "./Footer.css";

const Footer = () => {
  return (
    <div className="footer-bg">
      <Container fluid>
       
        <Row className="text-white p-5">
          <Col xs={12} md={6} className="private order-5 order-md-1">
            {/* <div > */}
              <Link className="text-decort" to="/">
                <img className="foot-image-size" src={logo} alt="logo" />{" "}
              </Link>
              <div>
                <div className="social-media">
                  <ul className="">
                    <li>
                      <a
                      href="https://www.facebook.com/profile.php?id=61552677631327"
                      target="_blank" rel="facebook"
                    >
                      <i
                        className="bi bi-facebook"
                        style={{ fontSize: "1.6rem", color: "#fff" }}
                      > </i>
                    </a>
                    </li>
                    <li>
                      <a href="https://www.instagram.com/vitalmri/" target="_blank" rel="instagram">
                        <i
                          className="bi bi-instagram"
                          style={{ fontSize: "1.6rem", color: "#fff" }}
                        ></i>
                      </a>
                    </li>
                    <li>
                    <a href="https://www.linkedin.com/company/vital-mri/" target="_blank" rel="linkedIn">
                    <i
                        className="bi bi-linkedin"
                        style={{ fontSize: "1.6rem", color: "#fff" }}
                      ></i>
                    </a>
                    </li>
                  </ul>
                </div>
                <div className="copyrights">
                  <div> &copy; 2024 Proudly powered by <a rel="noreferrer" style={{color:"#fc5185", textDecoration: "none"}} href= "https://betamonks.com/" target="_blank">BetaMONKS</a>. </div>
                  <ul className="text-decoration-underline">
                    <li >
                      Press
                    </li>
                    <li>
                      Privacy
                    </li>
                    <li>
                      Notice of Privacy Practices
                    </li>
                    <li>
                      Terms of Service
                    </li>
                  </ul>
                </div>
              </div>
            {/* </div> */}
          </Col>
          {/* <Col xs={6} md={2} className="order-4"></Col> */}
          <Col xs={6} md={2} className="order-1 order-md-2">
          <div className="single-footer-widget">
              <div className="widget-title">
                <h2>For You</h2>
              </div>
              <div className="widget-content">
                <Link className="footer-link" to="/Knowyourrisk">Know your risk</Link>
                <Link className="footer-link" to="/Pricing">Pricing</Link>
                <Link className="footer-link" to="/FAQ">FAQ'S</Link>
                <Link className="footer-link" to="/OurLocations">Locations</Link>
              </div>
            </div>
          </Col>
          <Col xs={6} md={2} className="order-3" >
          <div className="single-footer-widget">
              <div className="widget-title">
                <h2>About us</h2>
              </div>
              <div className="widget-content">
                <Link className="footer-link" to="/AboutUs">About</Link>
                {/* <Link className="footer-link"  to="/">Media & Press</Link> */}
                <Link className="footer-link" to="/ContactUs">Contact Us</Link>
                <span className="creator creator-sub" style={{color: "rgb(252, 81, 133)"}}>A unit of Aarthi scans and Labs.</span>
              </div>
            </div>
          </Col>
          {/* <Col xs={6} md={2} className="order-4">
          <div className="single-footer-widget">
                            <div className="widget-title">
                                <h2>Our Address</h2>
                            </div>
                            <div className="widget-content">
                                <NavLink className="footer-link">No 60, 100 Feet Rd, Vadapalani, Chennai, Tamil Nadu 600026, India</NavLink>
                                <NavLink className="footer-link">Phone:+91 75500 75500</NavLink>
                                <NavLink className="footer-link">Email:admin@aarthiscan.com</NavLink>
                            </div>
                        </div>
          </Col> */}
        </Row>
      </Container>
      {/* <div className="footer-copy-right text-center text-white mt-5">
        <p className="mb-0">
          &copy; 2023
          <span>
            <a
              href="https://betamonks.com/"
              target="_blank"
              className="creator"
            >
              BetaMONKS
            </a>
          </span>
          | All Rights Reserved
        </p>
      </div> */}
    </div>
  );
};

export default Footer;
