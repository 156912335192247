import { GET_NAV_MENU_STATUS } from "../types";

const initialState = {
  navMenuState: {},
};

const myFunc = (state = initialState, action) => {
  switch (action.type) {
    case GET_NAV_MENU_STATUS:
      return {
        ...state,
        navMenuState: action.payload,
      };
    default:
      return state;
  }
};
export default myFunc;
