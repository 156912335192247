// import AOS from "aos";
// import "aos/dist/aos.css";
import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import client from "../../images/testi1.jpg";
import client1 from "../../images/testi2.jpg";
import client2 from "../../images/testi3.jpg";
import customers from "../../customFunctions/CustomerData";
import "./Testimonial.css";
import Marquee from "react-fast-marquee";

const Testimonial = () => {
  const settings = {
    speed : 30,
    pauseOnHover : true,
    }
  return (
    <section className="testimonial-wrapper">
      <Container>
        <Row>
          <Col sm={12}>
            <div className="section-title text-center">
              <h1>Our Happy Customers</h1>
              <h5> Check what they say about us...</h5>
            </div>
          </Col>
        </Row>

        <Marquee className ="review-slider" {...settings}>
        <div className="cards">
          {customers.map((item, index) => (
            <Col md={4} lg={4} xl={4} xs={14} key={index}>
              <div className="card card-testimonial">
                <img src={ item.id === 1 ? client : item.id === 2 ? client1 : client2 } alt={item.name} />
                <p>{item.content}</p>
                <h2>{item.name}</h2>
                <h4>Age {item.age}</h4>
                <i className="fa fa-quote-left"></i>
              </div>
            </Col>
          ))}
        </div>
        </Marquee>
      </Container>
    </section>
  );
};

export default Testimonial;
