export const Organ =[
  { organ_id: 1, organ_name: "Brain" },
  { organ_id: 2, organ_name: "Eyes" },
  { organ_id: 3, organ_name: "Paranasal Sinuses" },
  { organ_id: 4, organ_name: "Oral Cavity" },
  { organ_id: 5, organ_name: "Neck" },
  { organ_id: 6, organ_name: "Chest" },
  { organ_id: 7, organ_name: "Abdomen" },
  { organ_id: 8, organ_name: "Pelvis" },
  { organ_id: 9, organ_name: "Hip Joint" },
  { organ_id: 10, organ_name: "Lower limbs" },
  { organ_id: 11, organ_name: "Cervical spine" },
  { organ_id: 12, organ_name: "Dorsal Spine" },
  { organ_id: 13, organ_name: "Lumbosacral spine" },
];

export default Organ;