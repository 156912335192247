import React from 'react';
import { Card, Container, Row } from 'react-bootstrap';

const Blog = () => {
return(
    <section>
    <header className="py-5 bg-light border-bottom mb-4">
    <Container>
        <div className="text-center my-5">
            <h1 className="fw-bolder">Welcome to Blog Home!</h1>
            <p className="lead mb-0">Hey, we're fullBody MRI, See our thoughts, stories and ideas.</p>
        </div>
    </Container>
</header>
<Container>
            <Row >
                {/* <!-- Blog entries--> */}
                <div className="col-lg-8">
                    {/* <!-- Featured blog post--> */}
                    <Card className="card mb-4">
                        <a href="#!"><img className="card-img-top" src="https://dummyimage.com/850x350/dee2e6/6c757d.jpg" alt="..." /></a>
                        <div className="card-body">
                            <div className="small text-muted">Newest</div>
                            <h2 className="card-title">What is a Full-Body MRI Scan?</h2>
                            <p className="card-text">A full-body MRI (Magnetic Resonance Imaging) scan is a non-invasive medical imaging procedure that provides detailed images of the entire body. It uses strong magnetic fields and radio waves to create images of internal structures, making it a valuable tool for healthcare professionals.</p>
                            {/* <a className="btn btn-primary" href="#!">Read more →</a> */}
                        </div>
                    </Card>
                    {/* <!-- Nested row for non-featured blog posts--> */}
                    <Row>
                        <div className="col-lg-6">
                            {/* <!-- Blog post--> */}
                            <Card className="mb-4">
                                <a href="#!"><img className="card-img-top" src="https://dummyimage.com/700x350/dee2e6/6c757d.jpg" alt="..." /></a>
                                <Card.Body>
                                    <div className="small text-muted">Newest</div>
                                    <Card.Title className="fs-4">A Step-by-Step Guide to Preparing for Your First MRI Scan</Card.Title>
                                    <Card.Text >Prepare for your first MRI scan with confidence through our step-by-step guide. Learn what to expect, from scheduling your appointment to arrival, and gain insights into essential preparations like clothing choices and fasting requirements, ensuring a smooth and stress-free experience.</Card.Text>
                                    {/* <a className="btn btn-primary" href="#!">Read more →</a> */}
                                </Card.Body>
                            </Card>
                            {/* <!-- Blog post--> */}
                            <Card className="mb-4">
                                <a href="#!"><img className="card-img-top" src="https://dummyimage.com/700x350/dee2e6/6c757d.jpg" alt="..." /></a>
                                <Card.Body>
                                    <div className="small text-muted">Newest</div>
                                    <Card.Title className="fs-4">Understanding Contrast Agents in MRI Scans: When and Why They're Used</Card.Title>
                                    <Card.Text>Delve into the utilization of contrast agents in MRI scans, examining their specific applications and the reasons behind their use. Discover how these agents enhance image clarity and assist in diagnosing medical conditions.</Card.Text>
                                    {/* <a className="btn btn-primary" href="#!">Read more →</a> */}
                                </Card.Body>
                            </Card>
                        </div>
                        <div className="col-lg-6">
                            {/* <!-- Blog post--> */}
                            <Card className="mb-4">
                                <a href="#!"><img className="card-img-top" src="https://dummyimage.com/700x350/dee2e6/6c757d.jpg" alt="..." /></a>
                                <Card.Body>
                                    <div className="small text-muted">Newest</div>
                                    <Card.Title className="fs-4">The Complete Guide to MRI Safety: What You Need to Know </Card.Title>
                                    <Card.Text>Dive into our comprehensive guide on MRI safety, offering valuable insights into the precautions, common concerns, and essential information every patient should be aware of before undergoing an MRI scan. Ensure a safe and stress-free experience with our expert advice and tips.</Card.Text>
                                    {/* <a className="btn btn-primary" href="#!">Read more →</a> */}
                                </Card.Body>
                            </Card>
                            {/* <!-- Blog post--> */}
                            <Card className="mb-4">
                                <a href="#!"><img className="card-img-top" src="https://dummyimage.com/700x350/dee2e6/6c757d.jpg" alt="..." /></a>
                                <Card.Body>
                                    <div className="small text-muted">Newest</div>
                                    <Card.Title className="fs-4">MRI vs. CT Scan: Which Imaging Technique Is Right for You?</Card.Title>
                                    <Card.Text>Explore the differences between MRI and CT scans, helping you determine the most suitable imaging method based on your medical needs. Understand how each technique works, their advantages, and when they are typically recommended by healthcare professionals.</Card.Text>
                                    {/* <a className="btn btn-primary" href="#!">Read more →</a> */}
                                </Card.Body>
                            </Card>
                        </div>
                    </Row>
                    {/* <!-- Pagination--> */}
                    {/* <nav aria-label="Pagination">
                        <hr className="my-0" />
                        <ul className="pagination justify-content-center my-4">
                            <li className="page-item disabled"><a className="page-link" href="#" tabIndex="-1" aria-disabled="true">Newer</a></li>
                            <li className="page-item active" aria-current="page"><a className="page-link" href="#!">1</a></li>
                            <li className="page-item"><a className="page-link" href="#!">2</a></li>
                            <li className="page-item"><a className="page-link" href="#!">3</a></li>
                            <li className="page-item disabled"><a className="page-link" href="#!">...</a></li>
                            <li className="page-item"><a className="page-link" href="#!">15</a></li>
                            <li className="page-item"><a className="page-link" href="#!">Older</a></li>
                        </ul>
                    </nav> */}
                </div>
                {/* <!-- Side widgets--> */}
                <div className="col-lg-4">
                    {/* <!-- Search widget--> */}
                    {/* <Card className="mb-4">
                        <div className="card-header">Search</div>
                        <div className="card-body">
                            <div className="input-group">
                                <input className="form-control" type="text" placeholder="Enter search term..." aria-label="Enter search term..." aria-describedby="button-search" />
                                <button className="btn btn-primary" id="button-search" type="button">Go!</button>
                            </div>
                        </div>
                    </Card> */}
                    {/* <!-- Categories widget--> */}
                    <Card className=" mb-4">
                        <div className="card-header">Categories</div>
                        <div className="card-body">
                            <div className="row">
                                <div className="col-sm-6">
                                    <ul className="list-unstyled mb-0">
                                         <li><a href="#!">Safety</a></li>
                                        <li><a href="#!">Patient</a></li>
                                        <li><a href="#!">Facilities</a></li>
                                    </ul>
                                </div>
                                <div className="col-sm-6">
                                    <ul className="list-unstyled mb-0">
                                        <li><a href="#!">Wellness</a></li>
                                        <li><a href="#!">Discoveries</a></li>
                                        <li><a href="#!">Community</a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </Card>
                    {/* <!-- Side widget--> */}
                    <Card className=" mb-4">
                        <div className="card-header">Preparing for Your Full-Body MRI Scan:</div>
                        <div className="card-body">To ensure the best results from your scan, it's important to follow preparation instructions provided by your healthcare provider. Common preparations may include fasting and removing metal objects.</div>
                    </Card>
                </div>
            </Row>
        </Container>
        </section>

);
}
export default Blog;
