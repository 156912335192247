export const customers = [
    {
        id: 1,
        name: 'Aadhya',
        age: '24',
        review:'Awesome Work',
        content: 'They really took my individual case to heart. Their team is very helpful. They all work together in an impressive way to make sure that my needs were met and I walked out pain free.',
      },
      {
        id: 2,
        name: 'Dhruv',
        age: '54',
        review:'Really I Happy with Aarthi',
        content: "Due to I visit with my wife in month 3 time for uterus ultrasound scanning Aarthi doctor's taken extra carrying",
      },
      {
        id: 3,
        name: 'Prisha',
        age: '43',
        review:'Well Equipped Laboratory',
        content: "Awesome service and prompt response provided by Aarthi Scans Center. For the HSG Test. So I approached them. They responded well and the service was really soo good. The impression report was sent through the mail.",
      },

];

export default customers;