export const FakeService = [
  {
    id: "001",
    title: "Peace of mind",
    description:
      "Get a deep understanding of your health & stay one step ahead of potential diseases",
  },
  {
    id: "002",
    title: "Concierge Service",
    description:
      "One dedicated coordinator will be with you from booking to scan and report explanation",
    },
//   {
//     id: "003",
//     title: "Dedicated 1-1 care",
//     description:
//       "Have a dedicated clinician guiding you through your scan with pre and post scan consultations.",
//     link: "Read More",
//     img: "https://raw.githubusercontent.com/saifulemon/images-for-all-project/main/Donto/Services/dictionary.svg",
//   },
  {
    id: "004",
    title: "Report Explanation by Dr",
    description:
      "Our clinician will walk you through your results to help you understand your report",
   }
];
export default FakeService;
