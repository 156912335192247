import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { Link } from "react-router-dom";
import './Symptoms.css';

const Symptoms =()=> {

    return(
        <section className="whyMri-wrapper">
        <Container>
            <Row className="align-items-center">
                <Col md={6} lg={4} sm={12}>
                    <div className="image-box" data-aos="fade-right"></div>
                  
                </Col>
                <Col md={6} lg={8} sm={12}>
                    <div className="text-center" data-aos="zoom-in">
                        {/* <h1>Our annual scan catches diseases earlier, making them easier to beat.</h1> */}
                        <h1>Screening is our best defence against cancer.</h1>
                        <p>"Whole Body MRI Scan may detect small cancers in early stage allowing you to seek treatment early. Our advanced MRI imaging techniques also analyse your risk of Stroke, Dementia and Liver disease to help you adjust your lifestyle now to have a longer happier life."</p>
                        <Link className="text-decort" to="/MRI"><button className="button btn-fill">Find Why MRI is better</button></Link>
                    </div>
                </Col>

            </Row>
        </Container>
    </section>
    )
}
export default Symptoms;