export const QuestionAndAnswer = [
  {
    id: 1,
    question: "What is your name?",
    type: "input",
    name: "name",
    genderBased: "both",
    placeHolder: "Enter Your Name",
    inputType:"text",
  },
  {
    id: 2,
    question: "What is your age?",
    type: "input",
    name: "age",
    keyword: "years",
    genderBased: "both",
    placeHolder: "Enter Your Age",
    inputType:"number",
  },
  {
    id: 3,
    question: "What was your sex at birth?",
    type: "select",
    name: "gender",
    options: ["Male", "Female"],
    genderBased: "both",
    
  },
  {
    id: 4,
    question: "What is your current height? (in cms)",
    type: "input",
    name: "height",
    keyword: "cms",
    genderBased: "both",
    inputType:"number",
  },
  {
    id: 5,
    question: "What is your current weight? (in Kgs)",
    type: "input",
    name: "weight",
    keyword: "Kgs",
    genderBased: "both",
    inputType:"number",
  },
  {
    id: 6,
    question:
      "Do you have a family history of any of the following cancers (Parents or Siblings)",
    type: "multiselect",
    options: [
      "No History",
      "Breast Cancer",
      "Ovarian Cancer",
      "Endometrial (uterus) Cancer",
      "Prostate Cancer",
      "Lung Cancer",
      "Pancreatic Cancer",
      "Intestine Cancer",
      "Kidney Cancer",
      "Urinary Bladder Cancer",
    ],
    genderBased: "both",
  },
  {
    id: 7,
    question:
      "Are you moderately active (brisk walk / exercise) for at least 30 minutes each day, or at least 3 hours each week?",
    type: "select",
    options: ["No","Yes"],
    genderBased: "both",
  },
  {
    id: 8,
    question: "On average, how many alcoholic drinks do you have in a day ?",
    type: "select",
    options: [`I don't drink`, `1 glass a day`, `greater than 1 glass a day`],
    genderBased: "both",
  },
  {
    id: 9,
    question: "Do you smoke cigarettes?",
    type: "select",
    options: [`No`, `Yes, Less than 15 cigarettes per day`, `Yes, More than 15 cigarettes per day`, `I used to smoke, but quit now`],
    genderBased: "both",
  },
  {
    id: 10,
    question: "Have you lived with a smoker for more than half of your life?",
    type: "select",
    options: ["Yes", "No"],
    genderBased: "both",
  },
  {
    id: 11,
    question: "How many times have you given birth?",
    type: "select",
    options: ["Never", "Once or more, First delivery at <35yrs of age", "Once or more, First delivery at >35yrs of age"],
    genderBased: "Female",
  },
  {
    id: 12,
    question: "How old were you when you started menstruating or having your period?",
    type: "select",
    options: ["Less than 11 yrs", "11 yrs or older"],
    genderBased: "Female",
  },
  {
    id: 13,
    question: "Do you use oral contracetives pills regularly?",
    type: "select",
    options: ["No", "Yes", "I don't know"],
    genderBased: "Female",
  },
  {
    id: 14,
    question: "Have you ever used ovulation inducing drugs ? (infertility treatment)",
    type: "select",
    options: ["No", "Yes", "I don't know"],
    genderBased: "Female",
  },
  {
    id: 15,
    question: "Do you have irregular periods?",
    type: "select",
    options: ["No", "Yes"],
    genderBased: "Female",
  },
  {
    id: 16,
    question: "Have you had Vasectomy (birth control surgery for males)?",
    type: "select",
    options: ["No", "Yes", "I don't know"],
    genderBased: "male",
  },
  {
    id: 17,
    question: "Do you live in a metro city ? Or work in an area with significant air pollution?",
    type: "select",
    options: ["No", "Yes"],
    genderBased: "both",
  },
  {
    id: 18,
    question: "Do you drink chlorinated water? (water with a distinct chlorine taste and odour)",
    type: "select",
    options: ["No", "Yes"],
    genderBased: "both",
  },
  {
    id: 19,
    question: "Are you a diabetic ? (sugar patient)",
    type: "select",
    options: ["No", "Yes"],
    genderBased: "both",
  },
  {
    id: 20,
    question: "Do you have high blood pressure?",
    type: "select",
    options: ["No", "Yes"],
    genderBased: "both",
  },
  {
    id: 21,
    question: "Did you ever suffer from pancreatitis in the past?",
    type: "select",
    options: [`No`, `Yes`, `I don't know`],
    genderBased: "both",
  },
  {
    id: 22,
    question: "Have you ever been diagnosed with inflammatory bowel disease?",
    type: "select",
    options: [`No`, `Yes`, `I don't know`],
    genderBased: "both",
  }
];

