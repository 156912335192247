import { Col, Container, Row } from 'react-bootstrap';
import { Link } from "react-router-dom";
import lab from '../../images/nurse1.png';
import './Banner.css';

const Banner = () => {
    return (
            <section className="single-hero-slide ">
                <Container fluid>
                    <Row className="align-items-center">
                        <Col md={12} sm={12} lg={6}>
                            <div className="hero-slide-left">
                                <div className='top-content'>
                                    <h1 className="pb-2">One Scan.</h1>
                                    <h1>
                                    500+ Diseases
                                    <br/> Screened.</h1>
                                </div>
                                
                                <p className="d-flex justify-content-center align-items-center">Our Vital MRI scan covers most of your essential organs and provides deep insight into your overall health.</p>
                                <div className="banner-btn m-sm-auto">
                                   
                                <Link className="text-decort" to="/Knowyourrisk"><button className='button btn-fill'>Take a Risk Test</button></Link>
                                        
                                    {/* <button className='theme-btn bth-blank'>Take a Risk Test</button> */}
                                </div>
                                <div className='sub-header'>
                                    <h5>Discover how Vital MRI can help you.</h5>
                                    <ul className='subHeader-list'>
                                        <li>Whole Body MRI Scan from head to toe</li>
                                        <li>Detect cancers, aneurysms, neurological and abdominal disorders before symptoms!</li>
                                        <li>Safe - No Radiation & Non-invasive</li>
                                    </ul>
                                    <div className='d-flex justify-content-center'>
                                    <Link to="/Pricing" className="text-decort"><button className="button btn-fill">Book an Appointment</button></Link>
                                    </div>
                                </div>

                                
                                
                            </div>
                        </Col>
                        <Col md={12} sm={12} lg={6} className="mt-sm-5">
                            <div className="hero-slide-right text-center text-lg-start">
                                <div className="animate-img">
                                    {/* <img src={womanbrush} alt="" className="img-fluid aimg1" /> */}
                                    {/* <img src={vector} alt="" className="img-fluid aimg2" /> */}
                                </div>
                                <div>
                                <img src={lab} alt="" className="heroTeeth"/>
                                </div>
                                
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>
    );
};

export default Banner;