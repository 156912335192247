import React from "react";
import Banner from "../../Banner/Banner";
import Bodyparts from "../../Bodyparts/Bodyparts";
import WorksCarousel from "../../Carousel/WorksCarousel";
import FaQuestions from "../../FAQ/FaQuestions";
import Promo from "../../Promo/Promo";
import Service from "../../Services/Service";
import StickyPage from "../../Sticky/StickyPage";
import Symptoms from "../../Symptoms/Symptoms";
import Testimonial from "../../Testimonial/Testimonial";
import './HomePage.css';


const Home = () => {

    return (
        <>
           
                <Banner />
                <div className="boundery-sticky">
                <Symptoms />
                <Service />
                 <div className="multi-background">
                <Bodyparts />
                <WorksCarousel />
                </div>
                <div className="ball-background">
                <Promo />
                <Testimonial />
                </div>
                <FaQuestions />
                <StickyPage />
                </div>

                
             
        </>
        
        )

}

export default Home;
