import AOS from "aos";
import "aos/dist/aos.css";
import React, { useEffect } from "react";
import { Col } from "react-bootstrap";
import SleepIcon from "../../images/sleep.png";
import WaitingIcon from "../../images/waiting-room.png";
import ReferalIcon from "../../images/conversation.png";
import "./Service.css";

const Services = (props) => {
  const { id, title, description} = props.treatment;
  useEffect(() => {
    AOS.init({
      duration: 2000,
    });
    AOS.refresh();
  }, []);


  return (
    <>
      <Col md={4} lg={4} xl={4} xs={12}>
        <div className="single-service-box" data-aos="flip-left">
          <div className="service-icon">
            <img className ="icons-service" src={id === "001" ? SleepIcon : id === "002"? WaitingIcon : ReferalIcon} alt="img" />
            {/* {id === "001" ? SleepIcon : id === "002"?<DoctorIcon /> :<ReferalIcon />} */}
          </div>
          <h3>{title}</h3>
          <p>{description}</p>
        </div>
      </Col>
    </>
  );
};

export default Services;
