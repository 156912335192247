import React, { useEffect, useState } from "react";
// import { ToggleButton, ToggleButtonGroup } from "react-bootstrap";
import { Card, Col, Container, Row } from "react-bootstrap";
import ToggleButton from "react-bootstrap/ToggleButton";
import ToggleButtonGroup from "react-bootstrap/ToggleButtonGroup";
import Pagination from "react-js-pagination";
import Organ from "../../../customFunctions/OrganData";
import TotalOrganDescription from "../../../customFunctions/OrganDescriptionData";
import "./MriHeader.css";

const OrganScan = () => {
  const [value, setValue] = useState(0);
  const [pageRangeDisplayed, setPageRangeDisplayed] = useState(5);
  const [activePage, setActivePage] = useState(1);
  const [organDescri, setOrganDescri] = useState(TotalOrganDescription);

  const itemsPerPage = 9;
  const startIndex = (activePage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const currentPageItems = organDescri.slice(startIndex, endIndex);

  useEffect(() => {
    function handleResize() {
      if (window.innerWidth >= 1000) {
        setPageRangeDisplayed(10);
      } else if (window.innerWidth <= 768) {
        setPageRangeDisplayed(3); // Adjust this value as needed
      } else {
        setPageRangeDisplayed(5); // Default value
      }
    }
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    console.log("select",value);
    if (value !== 0) {
      setOrganDescri(
        TotalOrganDescription.filter((item) => value === (item.organ_id))
      );

    } else {
      setOrganDescri(TotalOrganDescription);
    }
  }, [value]);

  const handlePageChange = (pageNumber) => {
    setActivePage(pageNumber);
  };
  const handleChange = (val) => {
    setValue(val);
    setActivePage(1)
  };

  return (
    <>
      <section className="healing p-5">
        <Container>
          <Row>
            <div className="mt-5 mt-lg-0">
              <p className="text-sm-start text-uppercase">Organs and Diseases Screened</p>
            </div>
          </Row>

          <Container>
            <ToggleButtonGroup
              className="organs-btns"
              // type="checkbox"
              type="radio"
              name="organSelect"
              value={value}
              onChange={handleChange}
            >
              {Organ.map((item, index) => (
                <ToggleButton
                  // id="tbg-btn-1"
                  key={index}
                  variant="outline-info"
                  className="orgn-btn"
                  value={item.organ_id}
                >
                  {(item.organ_name).toUpperCase()}
                </ToggleButton>
              ))}
            </ToggleButtonGroup>
          </Container>
        </Container>
      </section>

      <section className="orgn-view">
        
        {/* <div className="key-count">
          <span>Showing {currentPageItems.length} out of {organDescri.length} results</span>
        </div> */}
        <div className="w-100 p-3 pt-5">
          <Row className="orgn-view">
            {currentPageItems.map((item, index) => (
              <Col className="px-0 d-flex justify-content-center" key={index}>
                <Card className="bg-light text-dark organDescri">
                  <Card.Header className="organDescri-header mt-2 px-0 mx-3">
                    {" "}
                    <h4>{item.organ_title}</h4>
                  </Card.Header>
                  {/* <Card.Body
                    className="overflow-auto"
                    style={{ marginBottom: "1rem" }}
                  >
                    <Card.Text>{item.organ_description}</Card.Text>
                  </Card.Body> */}
                </Card>
              </Col>
            ))}
          </Row>
          <Pagination
            itemClass="page-item"
            linkClass="page-link"
            prevPageText="PREV"
            nextPageText="NEXT"
            hideFirstLastPages={false}
            pageRangeDisplayed={pageRangeDisplayed}
            activePage={activePage}
            itemsCountPerPage={9}
            totalItemsCount={organDescri.length}
            onChange={(i) => handlePageChange(i)}
          />
        </div>
      </section>
    </>
  );
};
export default OrganScan;
