import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import Testimonial from "../Testimonial/Testimonial";
import "./About.css";
import Radiologists from "./Radiologists";
const AboutBanner = () => {
  return (
    <section className="banner-all">
      <Container fluid>
        <Row className="text-center banner-about align-items-center">
          <Col md={6} lg={6} sm={12}>
            <div className="">
              <h1>About Us </h1>
              <h5 className="ml-2">- Vital MRI</h5>
            </div>
          </Col>
          <Col md={6} lg={6} sm={12}>
            <div className="text-start">
              {/* <h4 className="ml-2">- Vital MRI</h4> */}
              <h4 className="tagline">
                Prevention is so much better than healing because it saves the
                labor of being sick.
              </h4>
            </div>
          </Col>
        </Row>
        <Row>
          <p className="px-5">
          Vital MRI is an unit of Aarthi Scans and Labs. With 36 years of trust and presence in 11 states and 250+ branches, Aarthi Scans and Labs is India's largest and trusted integrated diagnostics provider. We at Vital MRI stand as India's
            pioneering full-body MRI service provider, with a clear focus on
            anticipating and intercepting diseases before they escalate. In a
            nation where the healthcare narrative has often revolved around
            treatment rather than prevention, we're reshaping the story. Our
            capability to detect over 500 diseases, including many types of
            cancers at their earliest stages, moves beyond just diagnosis; it's
            about proactive intervention and longevity !
          </p>
        </Row>
      </Container>
      <Container>
        <Row>
          <div className="vstack gap-2 aboutStack">
            {/* <h4 className="text-center about-header">
              Our Commitment to Excellence
            </h4> */}
            <Row className="p-2 my-4">
              <Col lg={4} md={7}>
              <div className="why-vital aos-init aos-animate" data-aos="fade-right"></div></Col>
              <Col className="align-self-center">
                <h6 className="text-uppercase text-muted subhead">
                  Why Vital MRI ?
                </h6>
                <p className="content-about">
                  India's health statistics are a cause for concern. Each year,
                  millions of Indians get diagnoses that might have had more
                  optimistic outcomes, if only they were identified earlier. The
                  essentiality of early detection remains immeasurable, and this
                  is the chasm Vital MRI earnestly endeavors to bridge.
                </p>
              </Col>
            </Row>
            <div className="p-2">
              <div>
                <h6 className="text-uppercase text-muted subhead">
                  Our Inspiration
                </h6>
                <p className="content-about">
                  The essence of Vital MRI is deeply personal and urgent. <br />
                  <br /> In India: <br />
                  <br />- 1 in 8 women are at risk of developing breast cancer
                  during their lifetime. <br />- A staggering 70% of cancer
                  patients in India only learn of their condition at an advanced
                  stage. <br />- Most aneurysms are only discovered when they
                  bleed into the brain causing permanent disability / death.
                  <br />
                  <br /> These aren't mere statistics for us. They are stories
                  of real individuals, their dreams halted, and futures
                  uncertain. Many of these late-stage diagnoses result in
                  avoidable complications and grief. Vital MRI is our commitment
                  to change this trajectory, to offer a chance at early
                  detection and hope.
                </p>
              </div>
            </div>
            <div className="p-2">
              <div>
                <h6 className="text-uppercase text-muted subhead">
                  Redefining the Diagnosis Cycle
                </h6>
                <p className="content-about">
                  Traditionally, the journey towards a diagnosis starts when
                  discernible, often agonizing, symptoms arise. A visit to the
                  doctor follows, tests get recommended, and a diagnosis is
                  eventually made. But what if we could rewrite this? What if
                  potential health threats were identified even before symptoms
                  began to surface? - This will be a photo. Beehigh will provide<br/><br/>This is the transformative vision Vital MRI brings to the table. It's not solely about recognizing a condition but catching its genesis, gifting you the power of foresight. By targeting the root causes at their infancy, we can alleviate the subsequent hardships diseases can impose.
                </p>
              </div>
            </div>
            <Row className="d-flex justify-content-center">
              <Col className="redefine-cyc" lg={7} sm={5}>
              
              </Col>
            </Row>
            <div className="p-2 mob-radialogy">
              <div>
                <h6 className="text-uppercase text-muted subhead">
                Our Radiologists
                </h6>
                <p className="content-about">
                Driving our mission forward are our exceptional radiologists.
                </p>
              </div>
            </div>
          </div>
        </Row>
      </Container>
      <div className="mob-radialogy">
          <Radiologists />
          </div>
      <Container fluid className="web-radialogy">
      <Row className="cardbackground d-flex justify-content-center">
              <div className="top-fit">
                  <h3> Our Radiologists</h3>
                  <p>Driving our mission forward are our exceptional radiologists.</p>
              </div>
          </Row>
          <Radiologists className="radiologist-cards"/>
      </Container>
      <div className="ball-background">
        <Testimonial />
      </div>
    </section>
  );
};

export default AboutBanner;
