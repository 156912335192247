import React, { useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import questions from "../../customFunctions/QuestionAndAnswer";
import faqQuestions from "../../customFunctions/FaqQuestionAndAnswer";
import AccordionItem from "./AccordionItem";
import { CaretRightOutlined } from '@ant-design/icons';
import { Collapse, theme, ConfigProvider } from 'antd';
import "./FaQuestions.css";

function FaQuestions() {
  const [active, setActive] = useState(null);
  const { Panel } = Collapse;

  const handleToggle = (index) => {
    if (active === index) {
      setActive(null);
    } else {
      setActive(index);
    }
  };

  const { token } = theme.useToken();
  const panelStyle = {
    marginBottom: 24,
    background: token.colorFillAlter,
    borderRadius: token.borderRadiusLG,
    border: 'none',
  };

  const customHeaderStyle = { color: 'blue' };
  return (
    <section className="question-wrapper">
      <Container>
        <Row>
          <Col sm={12} md={12} className="six">
            <h1>
              Frequently Asked Questions <span>WHAT TO KNOW</span>
            </h1>
          </Col>
        </Row>

        <Row className="justify-content-center mt-5 ">
          {/* <Col md={12} sm={12} lg={3} className ='question-img me-5'>
            <div className="d-flex justify-content-center">
            <img src={faqImg} alt="faq-question" />
            </div>
           
          </Col> */}
          <Col md={13} sm={12} lg={10} className="mt-2">
            <div className="card card-question">
              <div className="card-body">
                {/* {questions.map((faq, index) => {
                  return (
                    <AccordionItem
                      key={index}
                      active={active}
                      handleToggle={handleToggle}
                      faq={faq}
                    />
                  );
                })} */}

      {/* <ConfigProvider
    theme={{
      token: {
        // Seed Token
        // colorPrimary: '#00b96b',
        // borderRadius: 2,
        // Alias Token
        // colorBgContainer: '#f6ffed',
        colorText:"#475f7b",
      },
    }}
  > */}
          <Collapse accordion
          size="large"
           bordered={false}
           expandIcon={({ isActive }) => <CaretRightOutlined rotate={isActive ? 90 : 0} />}
          
           style={{
            background: token.colorBgContainer,
            
          }}
          items={panelStyle}
           >
            
            {faqQuestions.map((section, index) => (
              
              <Panel header={section.header} key={index} style={customHeaderStyle}>
              <Collapse className="custom-accordion" expandIconPosition="end" accordion size="middle" items={section.questions} header={section.question} />
              </Panel>
            ))}
          </Collapse>
                 
          {/* </ConfigProvider> */}
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
}
export default FaQuestions;
