import React, { useEffect, useRef} from "react";
import { Col, Row } from "react-bootstrap";
import Slider from "react-slick";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import { dataHowWorks } from "../../customFunctions/Custom";
// import book from "../../images/book.png";
// import result from "../../images/medical-result.png";
// import mri from "../../images/mri.png";
// import ngo from "../../images/ngo.png";
// import transparency from "../../images/transparency.png";
import "./WorksCarosel.css";

function disableEmpty() {
  const emptyElements = document.querySelectorAll(".empty");
  emptyElements.forEach((emptyElement) => {
    const grandparent = emptyElement.parentElement.parentElement;
    grandparent.classList.add("disable-focus-on-select");
  });
}

function WorksCarousel() {
  const sliderRef = useRef(null);

  // const next = () => {

  //     sliderRef.current.slickNext();
  // };

  // const previous = () => {
  //   sliderRef.current.slickPrev();
  // };

  // const [defaultImage, setDefaultImage] = useState({});
  const settings = {
    // className: "center",
    // lazyLoad: true,
    // slidesToShow: 3,
    // centerPadding: "60px",
    // speed: 500,
    // initialSlide: 0,
    // focusOnSelect: true,

    dots: false,
    arrows: true,
    infinite: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 3,
    responsive: [
      {
        breakpoint: 1000,
        settings: {
          slidesToShow: 2,
        }
      },
      {
        breakpoint: 800,
        settings: {
          slidesToShow: 2,
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          // slidesToScroll: 2,
          initialSlide: 0,
        },
      },
      {
        breakpoint: 445,
        settings: {
          speed: 500,
          slidesToShow: 1,
          slidesToScroll: 1,
          // arrows: true,
          // dots: true,
        },
      },
    ],
  };

  // const handleErrorImage = (data) => {
  //   setDefaultImage((prev) => ({
  //     ...prev,
  //     [data.target.alt]: data.target.alt,
  //   }));
  // };
  useEffect(() => {
    disableEmpty();
  }, []);

  return (
    
    <div className="slider-app">
        

    <div>
    {/* <PencilArt /> */}
      <Row>
          <p>The Vital MRI Scan experience</p>
          <h2>How It Works</h2>
    </Row>
    <Row>
      <Slider ref={sliderRef} {...settings}>
        {dataHowWorks.map((item, index) => (
         
            <Row key={index} >
              <Col lg={8} md={10} sm={7} >
                <div className="card carousel-card">
                <div className="carousel-header">
                <h3>{item.roman}</h3>
                  {/* <img
                    src={item.id === 1 ? transparency : item.id === 2 ? book : item.id === 3 ? mri : item.id === 4 ? result : item.id === 5 ? ngo : item.title }
                    alt={item.title}
                    onError={handleErrorImage}
                  /> */}
                  </div>
                  <div className="carousel-body">
                    <h4 className="card-title">{item.title}</h4>
                    <p>{item.category}</p>
                  </div>
                </div>
              </Col>
            </Row>
        
        ))}
        {/* <div className="empty"></div>
        <div className="empty"></div> */}
      </Slider>
      
      <br />
      {/* <div className="carousel-btn-container">
        <button className="button-arrow carousel-button" onClick={previous}>
          <i class="bi bi-arrow-left-short fa-2x" />
        </button>
        <button className="button-arrow carousel-button" onClick={next}>
          <i class="bi bi-arrow-right-short fa-2x" />
        </button>
      </div> */}
      </Row>
      </div>
    </div>
  );
}

export default WorksCarousel;
