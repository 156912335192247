import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import AuthService from "../../session/AuthService";
import { smallTick } from "../MRI/WhyMRI/MriHeader";
import "./Appoinment.css";

const Pricing = () => {
  const savePackage = (e) => {
    if (e === "0") {
      AuthService.selectedMRI({ packageName: "Vital Full Body MRI", amount: "24000" });
    } else {
      AuthService.selectedMRI({ packageName: "Vital Full Body MRI Pro", amount: "27000" });
    }
  };

  const Tick = () => {
    return (
      <span
        className="d-inline-flex align-items-center justify-content-center text-white rounded-circle m-1 me-2"
        style={{
          backgroundColor: "#A9F1DF",
          width: "26px",
          height: "26px",
        }}
      >
        <i className="bi bi-check" style={{ color: "rgb(47, 57, 65)" }}></i>
      </span>
    );
  };

  return (
    <section className="pricing-wrapper">
      <Container fluid>
        <Row className="pricing-row1">
          <h1>Full Body Scans</h1>
          <p>
          Our Vital scan covers most of your essential organs and provides deep insight into your overall health.
          </p>
        </Row>
        <Row className="pricing-row2 webView-pricing">
          <Col className="segement-price">
            <Col className="top-segement boder-segment">
              <h4>Vital Full Body MRI</h4>
              <p>
              Our popular plan, includes full body MRI scan with pre and post scan assistance.
              </p>
              <h1 className="my-4 packageprice">&#x20B9; 24,000 </h1>
              <Link className="text-decort" to="/Appointment">
                <button
                  className="button btn-fill "
                  onClick={() => savePackage("0")}
                >
                  Book Your Scan
                </button>
              </Link>
            </Col>
            <Col className="second-segement boder-segment">
              <h6>What's included</h6>
              <ul className="included-list">
                <li>{Tick()}Full Body MRI Scan</li>
                <li>{Tick()}MRI Brain Age analysis</li>
                <li>{Tick()}Brain white matter hyper-intensities quantification</li>
                <li>{Tick()}Entire Spine screening</li>
                <li>{Tick()}Angiogram of Brain & Neck arteries</li>
                <li>{Tick()}Pre scan prep sessions</li>
                <li>{Tick()}Post scan Dr consultation</li>
              </ul>
            </Col>
            <Col className="third-segement">
              <h6>Diseases detected:</h6>
              <ul className="included-list">
                <li>{Tick()}Cancers & Other growths</li>
                <li>{Tick()}Aneurysms</li>
                <li>{Tick()}Infections & Inflammations</li>
                <li>{Tick()}Narrowing of arteries in neck & brain</li>
                <li>{Tick()}Congenital abnormalities and 500+ Diseases</li>
              </ul>
            </Col>
          </Col>
          <Col className="segement-price seg-ultra">
            <Col className="top-segement boder-segment">
              <h4>Vital Full Body MRI Pro</h4>
              <p>
              Our most extensive profile of blood tests along with full body MRI scan.
              </p>
              <h1 className="my-4 packageprice">₹ 27,000</h1>
              <Link className="text-decort" to="/Appointment">
                <button
                  className="button btn-fill"
                  onClick={() => savePackage("1")}
                >
                  Book Your Scan
                </button>
              </Link>
            </Col>
            <Col className="second-segement boder-segment">
              <h6>What's included</h6>
              <ul className="included-list">
                <li>{Tick()}Full Body MRI Scan</li>
                <li>{Tick()}<b>Metabolic panel- 130+ Blood tests </b></li>
                <li>{Tick()}<b>Free home sample collection </b></li>
                <li>{Tick()}<b>Everything in Vital Full Body MRI</b></li>
              </ul>
            </Col>
            <Col className="third-segement">
              <h6>Diseases detected:</h6>
              <ul className="included-list">
                <li>{Tick()}Metabolic diseases (Diabetes, Insulin resistance, Liver & Pancreatic function, Cardiac Risk markers, Renal microvascular function, Iron deficiency profile, Thyroid function, Cholesterol profile, Bone health etc)</li>
                <li>{Tick()}Everything in Vital Full Body MRI</li>
              </ul>
            </Col>
          </Col>
        </Row>
        <div className="mobile-view">
       
        <Row className="mobile-pricing pricing-bud">
          <h5 className="text-center">Vital Full Body MRI</h5>
          <p className="text-center">₹ 24,000</p>
          <Col className="included-mobile">
              <h6>What's included</h6>
              <ul className="">
                <li>{smallTick()}Full Body MRI Scan</li>
                <li>{smallTick()}MRI Brain Age analysis</li>
                <li>{smallTick()}Brain white matter hyper-intensities quantification</li>
                <li>{smallTick()}Entire Spine screening</li>
                <li>{smallTick()}Angiogram of Brain & Neck arteries</li>
                <li>{smallTick()}Pre scan prep sessions</li>
                <li>{smallTick()}Post scan Dr consultation</li>
              </ul>
              <div className="d-flex justify-content-end">
              <Link to="/Appointment" className="text-decort" onClick={() => savePackage("0")}><button className="button btn-fill" style={{padding: '0.5rem 1rem'}}> Book Now</button></Link>
              </div>
            </Col>
        </Row>

        <Row className="mobile-pricing pricing-pro">
        <div className="ribbon"><span>Pro</span></div>
          <h5 className="text-center">Vital Full Body MRI Pro</h5>
          <p className="text-center">₹ 27,000</p>
          <Col className="included-mobile">
              <h6>What's included</h6>
              <ul className="">
                <li>{smallTick()}Full Body MRI Scan</li>
                <li>{smallTick()}<b>Metabolic panel- 130+ Blood tests</b></li>
                <li>{smallTick()}<b>Free home sample collection</b> </li>
                <li>{smallTick()}<b>Everything in Vital Full Body MRI</b></li>
              </ul>
              <div className="d-flex justify-content-end">
              <Link to="/Appointment" className="text-decort" onClick={() => savePackage("1")}><button className="button btn-fill" style={{padding: '0.5rem 1rem'}}> Book Now</button></Link>
              </div>
            </Col>  
        </Row>
        </div>

      </Container>
    </section>
  );
};
export default Pricing;
