import React, { useEffect, useRef, useState } from "react";
import { Button, Col, Container, Form, Row, Spinner } from "react-bootstrap";
import Swal from "sweetalert2";
import BookingService from "../../api/BookingService";
import { IsExists } from "../../customFunctions/Custom";
import AuthService from "../../session/AuthService";
import { PaymentGate } from "./PaymentGateWay";
import moment from "moment";
import "./Appoinment.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faShoppingCart } from "@fortawesome/free-solid-svg-icons";

const Appointment = () => {
  // const selectValue = useSelector((e) => e.NavMenuState.navMenuState);

  const selectValue = AuthService.getSelectedMRI();
  const [validated, setValidated] = useState(false);
  const [formValues, setFormvalues] = useState({
    paymentType: "",
    ...selectValue,
  });
  const [showLoader, setShowLoader] = useState(false);
  const formRef = useRef(null);

  const handleForm = (event) => {
    setFormvalues({
      ...formValues,
      [event.target.name]: event.target.value,
    });
  };

  // useEffect(() => {
  //   console.log(formValues);
  // }, [formValues]);

  function getCurrentDate() {
    const currentDate = moment().format("YYYY-MM-DD");
    return currentDate;
  }

  const handleValidateClick = () => {
    if (formRef.current.checkValidity()) {
      if (!IsExists(selectValue.amount)) {
        Swal.fire({
          icon: "error",
          title: "please choose any package",
        }).then(function () {
          window.location = " /Pricing";
        });
      } else {
        setShowLoader(!showLoader);
        BookingService.postBooking(formValues).then((response) => {
          console.log("response", response);
          console.log(
            "response paymentType:::::::",
            response.data.data.payment_mode
          );
          console.log(
            "response.data.status === 1",
            response.data.payment_mode === "Online Payment"
          );
          if (
            response.data.status === 1 &&
            response.data.data.payment_mode === "Cash"
          ) {
            console.log(
              "paymentType is cash::",
              response.data.data.paymentType
            );
          //   Swal.fire({
          //     icon: "success",
          //     title: "Booked",
          //     html: `
          //   <ul class="list-group">
          //   <li class="list-group-item border-0">Your Appointment has been confirmed!</li>
          //   <li class="list-group-item border-0">Our team will be in contact with you shortly...</li>
          //   </ul>
          // `,
          //   }).then(function () {
          //     window.location = `${AuthService.getBaseUrl()}`;
          //   });
            window.location = "/appointment-booked";

            setShowLoader(false);
            setFormvalues({});
            formRef.current.reset();
            setValidated(false);
          } else if (
            response.data.status === 1 &&
            response.data.data.payment_mode === "Online Payment"
          ) {
            console.log(
              "paymentType is online::",
              response.data.data.payment_mode
            );

            PaymentGate(response.data.data);

            console.log("before:");
            console.log("wrwrwrwrrrw", AuthService.getpaymentStatus());
            console.log("after:");
          } else {
            Swal.fire({
              icon: "error",
              title: "Something went wrong",
              text: "",
            });
            setShowLoader(false);
          }
        });
      }
    }
    setValidated(true);
  };

  console.log("next render:", formValues);
  return (
    <section className="appoinment-wrapper">
      {/* <Loader isLoading={isLoading}/> */}
      {/* <Col sm={12}>
        <div className="booking-title">
          <h1>Book Your Appointment</h1>
        </div>
      </Col> */}
      <Container className="booking py-5">
        <Row className="align-items-center">
          {/* <Col md={12} sm={12} lg={6}>
            <div className="appoitment-img"></div>
          </Col> */}
          {/* <Col md={12} sm={12} lg={6}> */}
          <Col>
            {/* <div className="pricing-tag">
              <h2>{selectValue.name}</h2>
              <div class="price">&#x20B9; {selectValue.amount}</div>
          </div> */}

            <Col sm={12}>
              <div className="booking-title mb-4">
                <h1>Book Your Appointment</h1>
              </div>
            </Col>
            <Col className="price-cont">
              {selectValue.packageName ? (
                <div className="pricing-tag">
                  {/* <h3></h3> */}
                  <p className="price align-self-center">
                    <FontAwesomeIcon
                      icon={faShoppingCart}
                      style={{
                        "--fa-primary-color": "#000000",
                        "--fa-secondary-color": "#000000",
                      }}
                    />{" "}
                    &ensp;
                    <b>{selectValue.packageName}</b>&nbsp; &#x20B9;
                    {selectValue.amount}
                  </p>
                </div>
              ) : (
                <div className="pricing-tag">
                  <h4>please choose any package</h4>
                </div>
              )}
            </Col>

            <Form noValidate validated={validated} ref={formRef}>
              <Form.Group
                as={Col}
                md="9"
                controlId="validationCustom01"
                className="my-2 "
              >
                <Form.Label>Name</Form.Label>
                <Form.Control
                  required
                  type="text"
                  placeholder="Name"
                  value={formValues.name}
                  onChange={handleForm}
                  name="name"
                />
                <Form.Control.Feedback type="invalid">
                  Please provide a valid Name.
                </Form.Control.Feedback>
              </Form.Group>

              <Row>
                <Form.Group
                  as={Col}
                  md="6"
                  controlId="validationCustom03"
                  className="mx-auto my-2 mb-3"
                >
                  <Form.Label>Gender</Form.Label>
                  <Form.Control
                    // type="select"
                    as="select"
                    placeholder="Gender"
                    required
                    value={formValues.gender}
                    onChange={handleForm}
                    name="gender"
                  >
                    <option value="">Select Gender</option>
                    <option value="male">Male</option>
                    <option value="female">Female</option>
                    <option value="other">Other</option>
                  </Form.Control>
                  <Form.Control.Feedback type="invalid">
                    Please select a gender.
                  </Form.Control.Feedback>
                </Form.Group>

                <Form.Group
                  as={Col}
                  md="6"
                  controlId="validationCustom04"
                  className="mx-auto my-2"
                >
                  <Form.Label>Appointment Date</Form.Label>
                  <Form.Control
                    type="date"
                    required
                    min={getCurrentDate()}
                    onChange={handleForm}
                    value={formValues.date}
                    name="date"
                  />

                  <Form.Control.Feedback type="invalid">
                    Please provide a valid Date.
                  </Form.Control.Feedback>
                </Form.Group>
              </Row>

              <Row>
                <Form.Group
                  as={Col}
                  md="6"
                  controlId="validationCustom05"
                  className="mx-auto my-2 mb-3"
                >
                  <Form.Label>City</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="City"
                    required
                    value={formValues.city}
                    onChange={handleForm}
                    name="city"
                  />
                  <Form.Control.Feedback type="invalid">
                    Please provide a valid city.
                  </Form.Control.Feedback>
                </Form.Group>

                <Form.Group
                  as={Col}
                  md="6"
                  controlId="validationCustom06"
                  className="mx-auto my-2"
                >
                  <Form.Label>Phone No</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Phone No"
                    required
                    onChange={handleForm}
                    value={formValues.mobile}
                    name="mobile"
                    minLength={10}
                    onKeyPress={(e) => {
                      if (
                        !/[0-9+]/.test(e.key) ||
                        e.target.value.length >= 10
                      ) {
                        e.preventDefault();
                      }
                    }}
                  />
                  <Form.Control.Feedback type="invalid">
                    Please provide a valid Phone No.
                  </Form.Control.Feedback>
                </Form.Group>
              </Row>

              <Form.Group
                as={Col}
                md="9"
                controlId="validationCustom06"
                className="my-2 "
              >
                <Form.Label>Choose payment mode</Form.Label>

                <Form.Check
                  required
                  type="radio"
                  label="Cash"
                  value="Cash"
                  name="paymentType"
                  checked={formValues.paymentType === "Cash"}
                  onChange={handleForm}
                />
                <Form.Check
                  required
                  type="radio"
                  label="Online Payment"
                  value="Online Payment"
                  name="paymentType"
                  checked={formValues.paymentType === "Online Payment"}
                  onChange={handleForm}
                />
                <Form.Control.Feedback type="invalid">
                  Please provide a valid Name.
                </Form.Control.Feedback>
              </Form.Group>

              <Col className="col-md-9 mx-auto d-grid gap-2 mt-5">
                <Button
                  variant="primary"
                  size="lg"
                  onClick={handleValidateClick}
                  disabled={showLoader}
                  className="button btn-fill"
                >
                  Book Now
                  {showLoader && (
                    <Spinner animation="border" size="sm" color="white" />
                  )}
                </Button>
              </Col>
            </Form>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default Appointment;
