export const dataHowWorks = [
    {
      id: 1,
      title: 'Book your scan',
      category: 'Book online or get a call back from us.',
      roman:'I',
    },
    {
        id: 2,
        title: 'Choose a convenient branch',
        category: 'Choose a convenient scanning centre near you (we have 60+ branches in all major cities).',
        roman:'II',
      },
    {
      id: 3,
      title: 'Pre-scan preparation',
      category: 'Consult with our care coordinator to complete a medical history questionnaire and know about the scan.',
      roman:'III',
    },
    {
      id: 4,
      title: 'Scan',
      category: 'Have your scan on the day of your appointment. Our care coordinator will be with you every step of the way.',
      roman:'IV',
    },
    {
      id: 5,
      title: 'Report explained by Dr',
      category: 'Our clinician will walk you through your MRI results to help you understand your report better.',
      roman:'V',
    },

  ];

  export const IsExists = (data, checkZero) => {
    if (checkZero === true) {
      if (data === "0" || data === 0) {
        return false;
      }
    }
    if (data === null || data === undefined || data === "") {
      return false;
    } else {
      return true;
    }
  };