export const AnswerWeightage = [
    { id:6,
        "option2" : {"Breast": 2},
        "option3" : {"Ovarian": 2},
        "option4" : {"Endometrial": 2},
        "option5" : {"Prostate": 2},
        "option6" : {"Lung": 2},
        "option7" : {"Pancreatic": 2},
        "option8" : {"Colorectal": 2},
        "option9" : {"Kidney": 2},
        "option10" : {"Bladder": 2},
    },
    {
        id:7,
        "option1" :{"Breast": 1, "Prostate": 1, "Colorectal": 1}
    },
    {
        id:8,
        "option2" : {"Pancreatic": 1},
        "option3" : {"Breast": 1, "Pancreatic": 2, "Colorectal": 1},
    },
    {
        id:9,
        "option2" : {"Lung":3,"Pancreatic": 2, "Colorectal":2, "Kidney": 2},
        "option3" : {"Lung": 2, "Pancreatic": 1, "Colorectal": 1, "Kidney": 1},
        "option4" : {"Lung": 1},
    },
    {
        id:10,
        "option2" : {"Lung": 1},
    },
    {
        id:11,
        "option1" : {"Breast": 1, "Ovarian": 2, "Endometrial": 1},
        "option3" : {"Breast": 1, "Ovarian": 1},
    },
    {
        id:12,
        "option1" : {"Breast": 1}
    },
    {
        id:13,
        "option2" : {"Breast": 1}
    },
    {
        id:14,
        "option2" : {"Ovarian": 2}
    },
    {
        id:15,
        "option2" : {"Ovarian": 1}
    },
    {
        id:16,
        "option2" : {"Prostate": 2}
    },
    {
        id:17,
        "option2" : {"Lung": 1}
    },
    {
        id:18,
        "option2" : {"Bladder": 2}
    },
    {
        id:19,
        "option2" : {"Endometrial":2 ,"Pancreatic": 2}
    },
    {
        id:20,
        "option2" : {"Kidney":1}
    },
    {
        id:21,
        "option2" : {"Pancreatic":3}
    },
    {
        id:22,
        "option2" : {"Colorectal":2}
    },
]