export const Radiology = [
    {
        "id": "001",
        "name": "Dr Aarthi Govindarajan",
        "status": "Radiology Quality, Women's imaging",
        "course":"MDRD, EDiR (European Board)",
    },
    {
        "id": "002",
        "name": "Dr Arunkumar Govindarajan",
        "status": "Healthcare management, Neuroradiology",
        "course":"MRDR, FRCR (London), Harvard Alumni (General Management Program '23)",
    },{
        "id": "003",
        "name": "Dr Prasanna Vignesh",
        "status": "Affordability in Healthcare, Oncoradiology",
        "course":"MDRD",
    }
]