import Swal from "sweetalert2";
import BookingService from "../../api/BookingService";
import { RAZORPAY_KEY } from "../../config/app-config";
import { IsExists } from "../../customFunctions/Custom";
import logo from "../../images/logo.png";
import AuthService from "../../session/AuthService";

let responsedata ='';

export const PaymentGate = (props) => {
  console.log("PORPOPOS:,", props);
  let bookingData = props;
  

  console.log("I am payment gatway");

  let dataToDb = {
    id: bookingData.id,
  };

  BookingService.createRazorPayOrder(dataToDb)
    .then((response) => {
      if (response.data && response.data.data && response.data.data.payinfo) {
        let payData = response.data.data.payinfo;
        console.log("payData::", response.data.data, payData);
        if (IsExists(payData) && IsExists(payData.razor_orderid)) {
          console.log(payData, bookingData);
          openRazorPage(payData, bookingData);
          // setPaymentData(payData);
        } else {
          //  showBookingCreatedAlert(bookingData);
        }
        console.log(response.data.data.booking);
      } else {
        Error("Booking Insert Failed.!");
      }
    })
    .catch((error) => console.error(error));

    return responsedata;

};

export const openRazorPage = (paymentData, bookingData) => {
  console.log("RazorOpePage::::", paymentData, bookingData);
  
  // let $this = this;
  const paymentConstants = {
    key: RAZORPAY_KEY,
    // amount: parseFloat(bookingData.bookingTotal) * 100,
    currency: "INR",
    name: "Full Body MRI",
    image: { logo },
    description: `Payment Of ${bookingData.name}(${bookingData.id}) From Cart Page`,
    order_id: paymentData.razor_orderid,
    handler: function (response) {
      responsedata=1;
      console.log(response.razorpay_payment_id);
      console.log(response.razorpay_order_id);
      console.log(response.razorpay_signature);

      console.log("my response data::::::", response, paymentData, bookingData);
      if (
        IsExists(response.razorpay_payment_id) &&
        IsExists(response.razorpay_order_id) &&
        IsExists(response.razorpay_signature)
      ) {
        statusSuccess();
        
        console.log(
          "my response data success::::::",
          response,
          paymentData,
          bookingData
        );
        let obj = {
          order_status: "Success",
          tracking_id: response.razorpay_payment_id,
          razorpay_signature: response.razorpay_signature,
          paymentid: paymentData.paymentid,
          razor_orderid: paymentData.razor_orderid,
        };

        BookingService.paymentStatus(obj);
      } else {
        console.log("Payment Failed..!");
      }
    },
    modal: {
      ondismiss: function () {
        // window.location = AuthService.getBaseUrl();
        window.location = "/appointment-booked";
      },
    },
    prefill: {
      name: `${bookingData.name}`,
      email: `${bookingData.mailID}`,
      contact: `${bookingData.contactNo}`,
    },
    notes: {
      address: `${bookingData.address}`,
    },
    theme: {
      color: "#3399cc",
    },
  };

  console.log("paymentConstants", paymentConstants);
  // console.log()
  const RazorCheckoutPay = new window.Razorpay(paymentConstants);

  RazorCheckoutPay.on("payment.failed", function (response) {
    
    console.log("response", response);
    console.log("ErrorCode", response.error.code);
    console.log("ErrorDescription", response.error.description);
    console.log("ErrorSource", response.error.source);
    console.log("ErrorStep", response.error.step);
    console.log("ErrorReason", response.error.reason);
    console.log("ErrorOrderId", response.error.metadata.order_id);
    console.log("ErrorPaymentId", response.error.metadata.payment_id);

    statusFailed();

    BookingService.paymentStatus({
      order_status: response.error.reason,
      tracking_id: response.error.metadata.payment_id,
      razorpay_signature: "0",
      paymentid: paymentData.paymentid,
      razor_orderid: paymentData.razor_orderid,
    });
  });
  RazorCheckoutPay.open();

  return responsedata;
};

export function statusFailed(){

   Swal.fire({
      icon: "error",
      title: "Booking Failed",
      html: `
      <ul class="list-group">
      <li class="list-group-item border-0">Sorry!</li>
      </ul>
    `,
    }).then(function () {
      window.location = AuthService.getBaseUrl();
    })
}

export function statusSuccess(){

  // Swal.fire({
  //   icon: "success",
  //   title: "Booked",
  //   html: `
  //   <ul class="list-group">
  //   <li class="list-group-item border-0">Your Appointment has been confirmed!</li>
  //   <li class="list-group-item border-0">Our team will be in contact with you shortly...</li>
  //   </ul>
  // `,
  // }).then(function () {
    
  // })

  window.location = "/appointment-booked";
  
}

