import React from "react";
import { Col, Container, Row, Table } from "react-bootstrap";
import "./MriHeader.css";

export const Tick = () => {
  return (
    <span
      className="d-inline-flex align-items-center justify-content-center text-white rounded-circle m-1 me-2"
      style={{
        backgroundColor: "#b5ffb3",
        width: "26px",
        height: "26px",
      }}
    >
      <i className="bi bi-check" style={{ color: "rgb(47, 57, 65)" }}></i>
    </span>
  );
};

export const smallTick = () => {
  return (
    <span
      className="d-inline-flex align-items-center justify-content-center text-white rounded-circle m-1 me-2"
      style={{
        backgroundColor: "#A9F1DF",
        width: "10px",
        height: "10px",
      }}
    >
      <i className="bi bi-check" style={{ color: "rgb(47, 57, 65)" }}></i>
    </span>
  );
};

const Wrong = () => {
  return (
    <div
      className="d-inline-flex align-items-center justify-content-center text-white rounded-circle m-1 me-2"
      style={{
        backgroundColor: "#FEFFBE",
        width: "26px",
        height: "26px",
      }}
    >
      <i className="bi bi-x" style={{ color: "rgb(47, 57, 65)" }}></i>
    </div>
  );
};

const Minus = () => {
  return (
    <div
      className="d-inline-flex align-items-center justify-content-center text-white rounded-circle m-1 me-2"
      style={{
        backgroundColor: "#FFF",
        width: "26px",
        height: "26px",
      }}
    >
      <i className="bi bi-dash" style={{ color: "#858588" }}></i>
    </div>
  );
};

export const MriHeader = () => {
  return (
    <>
      <section className="healing p-5">
        <Container fluid className="our-tech-container">
          <Row className="align-items-center">
            <div className="our-tech text-center">
              {/* <h5 className="fw-bold">OUR TECHNOLOGY</h5> */}
              {/* <br /> */}
              <h2 className="fw-bold">
              "Cancer's greatest weapon is invisibility. Whole Body MRI scan reveals it."
              </h2>
              {/* <br /> */}
              {/* <p className="">
                Magnetic Resonance Imaging (MRI) scans are uniquely effective at
                accurately displaying abnormalities inside the body. When
                combined with our world-class healthcare team, we make MRI-based
                full-body screening fast, accurate, and affordable.
              </p> */}
            </div>
          </Row>
        </Container>
      </section>
      <section className="healing">
        <Row className="align-items-center mx-2">
          <Col lg={6} sm={12} xs={12} className="sticky-img align-margin">
            <div className="mt-5 mt-lg-0 my-4">
              <p className="text-sm-start text-uppercase">Organs Scanned and Modality Used</p>
              <h3 className="fw-normal lh-base">
              MRI scan uses no harmful radiation, MRI scan doesn't require contrast media injection.
              </h3>
            </div>

            <ul className="list-group above-legend w-100 mx-auto expert-list">
            <div className="list-group-item border-0">
                <Tick />
                Useful for early detection of diseases
              </div>
              <div className="list-group-item border-0">
                <Wrong />
                Not useful for early detection
              </div>
              <div className="list-group-item border-0">
                <Minus />
                Useful, but requires harmful radiation / contrast media injection.
              </div>
            </ul>
          </Col>

          <Col lg={6} sm={12} xs={12}>
            <div className="table-container mt-5 text-center">
              <Table className="scan-table" responsive="sm">
                <thead>
                  <tr>
                    <td>ORGAN</td>
                    <td>MRI FULL BODY PLUS</td>
                    <td>CT</td>
                    <td>ULTRASOUND</td>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>Brain</td>
                    <td>
                      <Tick />
                    </td>
                    <td>
                      <Minus />
                    </td>
                    <td>
                      <Wrong />
                    </td>
                  </tr>
                  <tr>
                    <td>Spine</td>
                    <td>
                      {" "}
                      <Tick />
                    </td>
                    <td>
                      <Minus />
                    </td>
                    <td>
                      <Wrong />
                    </td>
                  </tr>
                  <tr>
                    <td>Thyroid</td>
                    <td>
                      {" "}
                      <Tick />
                    </td>
                    <td>
                      <Minus />
                    </td>
                    <td>
                      <Tick />
                    </td>
                  </tr>
                  <tr>
                    <td>Lungs</td>
                    <td>
                      {" "}
                      <Minus />
                    </td>
                    <td>
                      <Minus />
                    </td>
                    <td>
                      <Wrong />
                    </td>
                  </tr>
                  <tr>
                    <td>Liver</td>
                    <td>
                      {" "}
                      <Tick />
                    </td>
                    <td>
                      <Minus />
                    </td>
                    <td>
                      <Tick />
                    </td>
                  </tr>
                  <tr>
                    <td>Gallbladder</td>
                    <td>
                      {" "}
                      <Tick />
                    </td>
                    <td>
                      <Minus />
                    </td>
                    <td>
                      <Tick />
                    </td>
                  </tr>
                  <tr>
                    <td>Pancreas</td>
                    <td>
                      {" "}
                      <Tick />
                    </td>
                    <td>
                      <Minus />
                    </td>
                    <td>
                      <Tick />
                    </td>
                  </tr>
                  <tr>
                    <td>Spleen</td>
                    <td>
                      {" "}
                      <Tick />
                    </td>
                    <td>
                      <Minus />
                    </td>
                    <td>
                      <Wrong />
                    </td>
                  </tr>
                  <tr>
                    <td>Kidney</td>
                    <td>
                      {" "}
                      <Tick />
                    </td>
                    <td>
                      <Minus />
                    </td>
                    <td>
                      <Tick />
                    </td>
                  </tr>
                  <tr>
                    <td>Adrenal Gland</td>
                    <td>
                      {" "}
                      <Tick />
                    </td>
                    <td>
                      <Minus />
                    </td>
                    <td>
                      <Tick />
                    </td>
                  </tr>
                  <tr>
                    <td>Bladder</td>
                    <td>
                      {" "}
                      <Tick />
                    </td>
                    <td>
                      <Minus />
                    </td>
                    <td>
                      <Tick />
                    </td>
                  </tr>
                  <tr>
                    <td>Ovary</td>
                    <td>
                      {" "}
                      <Tick />
                    </td>
                    <td>
                      <Wrong />
                    </td>
                    <td>
                      <Tick />
                    </td>
                  </tr>
                  <tr>
                    <td>Uterus</td>
                    <td>
                      {" "}
                      <Tick />
                    </td>
                    <td>
                      <Wrong />
                    </td>
                    <td>
                      <Tick />
                    </td>
                  </tr>
                  <tr>
                    <td>Prostate</td>
                    <td>
                      {" "}
                      <Tick />
                    </td>
                    <td>
                      <Minus />
                    </td>
                    <td>
                      <Wrong />
                    </td>
                  </tr>
                </tbody>
              </Table>
            </div>
            <ul className="list-group below-legend w-100 mx-auto expert-list">
              <div className="list-group-item border-0">
                <Tick />
                Useful for early detection of diseases
              </div>
              <div className="list-group-item border-0">
                <Wrong />
                Not useful for early detection
              </div>
              <div className="list-group-item border-0">
                <Minus />
                Useful, but requires harmful radiation / contrast media injection.
              </div>
            </ul>
          </Col>
        </Row>
      </section>
    </>
  );
};
