import React, { useState } from "react";
import { Button, Col, Container, Form, InputGroup, Row } from 'react-bootstrap';
import Sticky from "react-sticky-el";
import './StickyPage.css'
import callgif from '../../images/call1.gif'
import BookingService from "../../api/BookingService";
import Swal from "sweetalert2";

const StickyPage = () => {
    const [mobile, setMobile] = useState('');

    const handleMobileChange = (e) => {
        const numericValue = e.target.value.replace(/[^0-9+]/g, '');
        // if(mobile.length >= 9){
            setMobile(numericValue);
        // }
        
      };
      const onSubmitCall = (e) => {
            if(mobile.length === 10){
                    BookingService.getCallBack({mobile:mobile}).then((response) => {
                        if (response.data.status === 1) {
                            Swal.fire({
                                icon: "success",
                                title: "Call Back ",
                                html: `
                              <ul class="list-group">
                              <li class="list-group-item border-0">Your call back has been confirmed!</li>
                              <li class="list-group-item border-0">Our team will be in contact with you shortly...</li>
                              </ul>
                            `,
                              })

                        }else {
                            Swal.fire({
                                icon: "error",
                                title: "Something went wrong",
                                text: "",
                              });
                        }

            });

      };
    };

    return(
    <section >
       <Sticky bottomOffset={80} mode={'bottom'} boundaryElement={'.boundery-sticky'} >
        <Container fluid >
	<Row className="justify-content-end" >
		<Col xs={12} sm={8} md={7} lg={4}>
		    <div className="coupon bg-white rounded mb-3 d-flex justify-content-between">
                <div className="kiri p-3">
                    <div className="icon-container ">
                        <div className="icon-container_box">
                            <img src={callgif} width="85" alt="call button" className="" />
                        </div>
                    </div>
                </div>
                <div className="tengah py-3 px-4 d-flex w-100 justify-content-center">
                    <div>
                        <h3 className="lead">Get a call back from us </h3>
                        <InputGroup>
                                <Form.Control
                                size="sm"
                                className="sticky-input"
                                placeholder="Contact Number"
                                aria-label="Contact Number"
                                aria-describedby="basic-addon2"
                                name="mobile"
                                type="text"
                                minLength={10}
                                value={mobile}
                                onChange={handleMobileChange}
                                onKeyPress={(e) => {
                                    try {
                                        if (
                                            !/[0-9+]/.test(e.key) ||
                                            e.target.value.length >= 10
                                        ) {
                                            e.preventDefault();
                                        }
                                        
                                    } catch (error) {
                                            alert("error",error)
                                    }
                                
                                }}
                                />
                                <Button variant="outline-danger" id="button-addon2" onClick={onSubmitCall}>
                                Submit
                                </Button>
                            </InputGroup>
                    </div>
                </div>
            </div>
            </Col>
	</Row>
</Container>
        </Sticky>
    </section>
    )

}
export default StickyPage;