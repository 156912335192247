export const FaqQuestionAndAnswer = [
  {
    id: 1,
    header: "Full Body MRI Accuracy",
    questions: [{
        key:1,
        label: "Is Whole body MRI scan more accurate than a PET scan as a preventative imaging tool ?",
        children: `Due to its high radiation dose and contrast injection, PET-CT (Positron Emission Tomography - Computed Tomography) is rarely used as a preventative screening tool. MRI scan uses no radiation and no contrast injection. For detection of cancers, MRI is very close in accuracy to PET CT. Where as in detection of aneurysms, benign tumors, degeneration and many other brain disorders, MRI is a far better screening tool than PET CT.`,
    },
    {
        key:2,
        label: "What is the difference between a CT scan and an MRI ?",
        children: `MRI is better than CT for assessing many organs, including the uterus, brain, brain arteries, neck, breasts, abdomen, and pelvis. CT is better for assessing the lung,. MRI shows more precise images and is safer, as it does not use ionizing radiation.`,
    },{
        key:3,
        label: "In screening for tumors, which is better CT or MRI scan ?",
        children: `MRI is more sensitive and specific as a screening tool for detecting cancers. As a screening exam, a CT scan cannot use contrast, making it difficult to distinguish between tumors and benign lesions such as cysts, which have similar densities. CT scans can miss tumors and lead to unnecessary additional testing.`,
    },
    {
        key:4,
        label: "What is the difference between an ultrasound and an MRI ?",
        children: `Ultrasound shines at confirming kidney stones, as MRI is less able to see these small stones. However, ultrasound has a poor field of vision and is operator dependant, meaning it sometimes misses things. MRI is three-dimensional, so we can precisely locate any findings. We also take many different tissue weightings, allowing us to distinguish cysts, abscesses, and solid tumors, which an ultrasound scan cannot reliably differentiate.`,
    },{
        key:5,
        label: "How does your scan compare to 3T MRIs ?",
        children: `3T MRIs are best for detailed studies of some body parts, such as the brain or joints. However, they are not ideal for whole body imaging due to wavelength interference, imaging artifacts, and increased body temperature. Therefore, we use a 1.5T MRI for whole body imaging.`,
    },
    {
        key:6,
        label: "Do you use contrast in your MRIs ?",
        children: `MRI scan usually doesn’t require contrast administration. MRI scan uses a combination of different sequences whose inherent contrast itself is useful for differentiating various tumors from benign lesions without the need for injected contrast.`,
    },{
        key:7,
        label: "Why don't you image the heart in great detail ?",
        children: `We don’t image the heart in great detail because of the time it takes to do a good cardiac study. A detailed cardiac study takes around 60 minutes by itself and hence this is not part of our whole body scan.`,
    },
    {
        key: 8,
        label: "Can the Full Body MRI scans provide Information about my joints (e.g. shoulders, knees) ?",
        children: `While MRI is excellent for diagnosing musculoskeletal conditions (such as joint and cartilage issues in the knees), the Vital Full Body MRI scan does not scan the individual joints in upper or lower limbs. However, it can detect degenerative changes and effusions in these joints. If you have any joint symptoms or an injury, you can request for a separate detailed study of those specific joints during booking, which will incur additional payment.`,
    },{
        key:9,
        label: "How often should I get a Vital Full Body MRI scan ?",
        children: `We recommend getting a full-body MRI scan once a year, or every 12 months. This allows for the monitoring of more aggressive cancers, such as ovarian and pancreatic cancers, which may not have been present or too small to be detected in previous scans. Additionally, full-body MRI scans can be used to monitor overall health and detect potential problems early on.`,
    },
    {
        key: 10,
        label: "How can you detect Alzheimer's disease ?",
        children: `Our whole body MRI scan scan also includes a detailed examination of the brain, which we use to detect Alzheimer's disease. Detailed MRI images of brain segments can reveal tissue loss in the temporal lobes, which is indicative of Alzheimer's. We compare brain volume measurements from your previous scan to present scans to detect changes. We recommend a clinical cognitive assessment if you are concerned about having Alzheimer's.`,
    }],
},
{
    id: 2,
    header: "Appointment",
    questions: [{
        key:1,
        label: "Do I need a doctor referral or prescription for whole body MRI ?",
        children: `A full body MRI is a preventive imaging tool. Doctor prescription is not needed. However if there are any findings in the scan, you may need to visit a specialist doctor for further treatment.`,
    },
    {
        key:2,
        label: "Is there an age you recommend people be screened by whole body MRI scan ?",
        children: `Ultimately, the decision of whether or not to get a whole body MRI scan at a younger age is up to you. If you have a strong family history of cancer or other major diseases, it may be a wise choice. Cancer incidence increases with age, starting at around 40. However, younger-onset cancer, while less common, tends to be more aggressive. A whole body MRI scan at any age can be helpful, especially for establishing a baseline for your health.`,
    },{
        key:3,
        label: "What is the price of a Vital Whole body MRI scan ?",
        children: `Please visit our appointments page for pricing related information.`,
    },
    {
        key:4,
        label: "How do I make an appointment ?",
        children: `You can make an appointment right here on the website by clicking “Book an appointment” on the top this page. You can also send us an email at booking@vitalmri.com`,
    }],
},
{
    id: 3,
    header: "MRI & Safety",
    questions: [{
        key:1,
        label: "Are MRI scans safe ?",
        children: `Yes, MRI scans have been in use since the 1980s and have a very safe track record. Unlike other medical imaging procedures such as CT and X-ray, MRI scans don’t expose you to harmful radiation. However, people with a pacemaker or cochlear implant or aneurysm clips are adviced not to do a MRI scan.`,
    },
    {
        key:2,
        label: "Can I have an MRI if I have dental fillings or implants or gold teeth ?",
        children: `Yes. This is completely safe.`,
    },{
        key:3,
        label: "Can I have an MRI if I have a Copper T (contraceptive device) ?",
        children: `Yes. Copper T's are MRI-safe.`,
    },
    {
        key:4,
        label: "Can I have an MRI if I have tattoos ?",
        children: `Yes, people with tattoos can be safely scanned. There is a very small chance of warming in the area of the tattoo ink but this rarely occurs. `,
    },{
        key:5,
        label: "Can I have an MRI if I have a prosthetic metallic implant in joint or a bone ?",
        children: `Almost all implants are MRI-safe. There is a chance the implant will affect the images we can get from the surrounding tissues due to artifacts. There is a small chance of warming in the area of the implant but this rarely occurs with newer implants. `,
    },
    {
        key:6,
        label: "Are there any weight restrictions for doing a MRI scan ?",
        children: `Our MRI scan accomodates people upto 200 Kgs.`,
    },{
        key:7,
        label: "Can I have an MRI if I have a cochlear implant ?",
        children: `MRI safety depends on implant type. Even MRI safe implants may affect imaging quality in the head region.`,
    },
    {
        key: 8,
        label: "Can I be scanned if I have a pacemaker ?",
        children: `There is a risk that the MRI magnetic fields will disrupt a pacemaker's operation, and so we don’t recommend people with pacemaker for a MRI scan.`,
    },{
        key:9,
        label: "Can I do a full body MRI if I am pregnant ?",
        children: `MRIs are safe during pregnancy, but rarely can cause hearing problems in the fetus and increase amniotic fluid temperature. Therefore, we avoid full body MRIs unless your doctor decides the benefits outweigh the risks.`,
    },
    {
        key: 10,
        label: "Can I have an MRI if I have an intracranial aneurysm clip?",
        children: `Safety of MRI in patients with aneurysm clips depends on the type of clip. MRI is generally safe in patients with non-ferromagnetic aneurysm clips.`,
    }],
},
{
    id: 4,
    header: "Scanning Procedure",
    children: `Yes. MRI radio waves are generally safe in a controlled environment such as an MRI. Cell phones also use radio waves. Cellphones are allowed to heat the head to 1.6 watts per kilogram. MRI is allowed to heat 3.2 watts per kilogram. By this extension, if you are comfortable speaking on your cell phone for 1200 minutes per year (120 mins per month) then you can have 9 Prenuvo scans per year.`,
    questions: [{
        key:1,
        label: "Are there any restrictions on what I can drink on the day of the scan ?",
        children: `No. You can drink water, tea or black coffee. Importate note - You will be in the MRI machine for up to 75 mins, so we recommend that you use the restroom before going in.`,
    },
    {
        key:2,
        label: "Can I eat anything on the day of the scan ?",
        children: `We recommend that you fast for atleast 4 hours before your scan so we can take the clearest images of your stomach and gall bladder.`,
    },{
        key:3,
        label: "How should I dress for the scan?",
        children: `You will be given a set of scrubs (top and bottom) as per your size to wear during your scan. You can leave on your inner wear (without metal pins or hooks). You can also bring your own clothes (cotton wear without metal pins or zip or hooks).`,
    },
    {
        key:4,
        label: "Where do I put my belongings at the clinic ?",
        children: `Your belongings can be stored safely in a locker provided exclusively for you or you can give them to the person accompanying you. We advice against bringing any jewellery for the scan.`,
    },{
        key:5,
        label: "Is there anything I shouldn't bring into the MRI room ?",
        children: `Any metal, or electronic device can interfere with the scanner and pulled towards it. Never bring any of the following into the examination room: coins, jewellery, keys, cell phone, watch, hearing aids or other electronic/metal devices. `,
    },
    {
        key:6,
        label: "What should I expect during the scan?",
        children: `MRI is a painless and harmless procedure. In the scanner, you'll be comfortable and in contact with an MRI technologist at all times. The scanner makes thumping and humming noises, but we'll provide headphones or earplugs. Eye masks will also be issued to you. During abdominal scans, an automated voice will ask you to take a deep breath and hold your breath to prevent movement that could blur the images. After 15-20 seconds, it will tell you to breathe normally.`,
    },{
        key:7,
        label: "How long does a whole body MRI scan take ?",
        children: `The Full Body MRI scan should take roughly 60-75 minutes. These times are estimates and sometimes it may take longer due to other factors (e.g. height, weight and movement). Please arrive at the diagnostic center atleast 15 minutes prior to your scan appointment to speak to the radiology technician, change your clothes, and be on the MRI table at the time of your scheduled appointment.`,
    },
    {
        key: 8,
        label: "Is it possible to have a restroom break during the scan?",
        children: `Of course, if it is an emergency we will get you out of the scanner to go to the toilet. But the high accuracy of the scan relies on us imaging the same tissue in your body in a number of different ways, while in the same position. Our advice - You should limit how much you drink to ensure you can last the duration of the scan and kindly void your bladder before getting into the scanner.`,
    },{
        key:9,
        label: "Can I move when I am on the scanner ?",
        children: `We will make you as comfortable as possible for the scan to minimize the need to move. However, moving during the scan is not a safety issue, but it affects the image quality. Our scan is accurate because we image the same tissue in your body multiple times from different directions and at different weights. Movement can affect our ability to align these images and reduce the accuracy of our reading.`,
    },
    {
        key: 10,
        label: "What if I have an emergency during the scan? Or wish to communicate with the technician during the scan ?",
        children: `You will be given a squeeze ball at the start of the scan. You can squeeze this at any time to alert the technologist performing your scan.`,
    }],
},
{
    id: 5,
    header: "Scan Reports",
    children: `We recommend that you fast for 4 hours before your scan so we can take the clearest images of your stomach and gall bladder.`,
    questions: [{
        key:1,
        label: "Who interprets and reports whole body MRI scans ?",
        children: `A Radiologist (doctor specially trained in MRI and other radiology examinations) will report your scan. Radiologists at Vital MRI are highly experienced in reporting whole body MRI scans and are certified with a medical license.`,
    },
    {
        key:2,
        label: "How long does it take for the scan to be reported by a Radiologist ?",
        children: `We aim to report every customer’s whole body MRI scan report within 2 working days. `,
    },{
        key:3,
        label: "What does the scan report contain ?",
        children: `You will receive hard and soft copies of a patient-friendly report. Your patient report will include information on the different organs, and conditions we screen for. At the end of the report all pertinent points will be summarised for you in the Impression section with the next course of action suggestion.`,
    },
    {
        key:4,
        label: "What percentage of customers get a completely normal report ?",
        children: `Nearly all of us have abnormalities and findings, such as spine degeneration, due to our sedentary lifestyles. Many benign findings are useful to be aware of when healthy so they are not mistaken for something more serious later. Importantly, less than 5% of people receive life-altering news.`,
    },{
        key:5,
        label: "Do normal scan results mean that I definitely do not have cancer ?",
        children: `No medical study is 100% perfect, so a normal scan does not guarantee there is no cancer. A very early cancer (<1cm) may need time to grow in size before it can become detectable on a MRI scan. Also MRI does not screen for cancers of the blood and marrow.`,
    }],
}
];

export default FaqQuestionAndAnswer;