import { faMapPin } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import AssistantDirectionTwoToneIcon from "@mui/icons-material/AssistantDirectionTwoTone";
import PhoneTwoToneIcon from "@mui/icons-material/PhoneTwoTone";
import React, { useEffect, useState } from "react";
import {
  Card,
  Col,
  Container,
  Row,
  ToggleButton,
  ToggleButtonGroup,
} from "react-bootstrap";
import BookingService from "../../api/BookingService";
import "./Location.css";

const array = [];
const cities = new Set();
const OurLocation = () => {
  const [selectCity, setSelectCity] = useState("CHENNAI");
  const [locations, setLocations] = useState([]);
  const [dynamiclocations, setDynamiclocations] = useState([]);
  const cityOrder = [
    { city_name: "CHENNAI", city_id: 14 },
    { city_name: "BENGALURU", city_id: 3 },
    { city_name: "HYDERABAD", city_id: 13 },
    { city_name: "VIZAG", city_id: 48 },
    { city_name: "TRIVANDRUM", city_id: 42 },
    { city_name: "MUMBAI", city_id: 35 },
    { city_name: "PUNE", city_id: "01" },
    { city_name: "DELHI NCR", city_id: 37 },
    { city_name: "AHMEDABAD", city_id: "01" },
    { city_name: "KOLKATA", city_id: 43 },
    { city_name: "TAMILNADU", city_id: 0 },
  ];
  useEffect(() => {
    getMriLocation();
  }, []);

  const getMriLocation = () => {
    BookingService.getmrilocation({}).then((response) => {
      if (response.data.status === 1) {
        cityOrder.forEach((order) => {
          const matchingCity = response.data.data.find(
            (item) => item.cityId === order.city_id
          );
          if (matchingCity) {
            cities.add(matchingCity.cityName.toUpperCase());
          }
        });

        response.data.data.forEach((item) => {
          if (
            item.StateName.toUpperCase() !== "TAMILNADU" &&
            item.StateName.toUpperCase() !== "PONDICHERRY" &&
            !cities.has(item.cityName.toUpperCase)
          ) {
            cities.add(item.cityName.toUpperCase());
          }
        });

        response.data.data.forEach((item) => {
          if (
            (item.StateName.toUpperCase() === "TAMILNADU" &&
              item.cityName.toUpperCase() !== "CHENNAI") ||
            item.StateName.toUpperCase() === "PONDICHERRY"
          ) {
            cities.add("TAMILNADU");
          }
        });

        console.log("cities::::", array);

        setLocations(response.data.data);
        setDynamiclocations(response.data.data);
      }
    });
  };

  useEffect(() => {
    if (selectCity === "TAMILNADU") {
      setDynamiclocations(
        locations.filter(
          (item) =>
            (item.StateName.toUpperCase() === "PONDICHERRY" ||
              item.StateName.toUpperCase() === "TAMILNADU") &&
            item.cityName.toUpperCase() !== "CHENNAI"
        )
      );
    } else {
      console.log("default city",selectCity);
      setDynamiclocations(
        locations.filter((item) => selectCity === item.cityName.toUpperCase())
      );
      console.log( 'selectescities::::::',locations.filter((item) => selectCity === item.cityName.toUpperCase()));
    }
  }, [selectCity,locations]);

  const handleCity = (val) => {
    console.log(val);
    setSelectCity(val);
  };
  console.log(
    "select and ",
    dynamiclocations,
    "select citiess ",
    array,
    "selectCity",
    selectCity
  );

  return (
    <section className="contactUs-wrapper">
      <Container fluid className="my-5 position-relative">
        <div className="map-view"></div>
        <div className=" text-center map-text">
          <h2 className="dm">Our Locations</h2>
          <div>
            <p>Book your Full Body MRI at one of our locations </p>
            {/* <div className="box d-flex justify-content-center">
            <Autocomplete
              disablePortal
              value={value}
              onChange={handleSelect}
              id="combo-box-demo"
              options={top100Films}
              getOptionLabel={(option) => (option && option.city ? option.city.toUpperCase() : '')}
              sx={{ width: 300 }}
              renderInput={(params) => (
                <TextField {...params} label="Select Your City" />
              )}
            />
          </div> */}

            <Container>
              <ToggleButtonGroup
                className="cities-btns"
                type="radio"
                name="organSelect"
                value={selectCity}
                onChange={handleCity}
                defaultValue={"CHENNAI"}
              >
                {Array.from(cities)?.map((item, index) => (
                  <ToggleButton
                    // id="tbg-btn-1"
                    key={index}
                    variant="outline-info"
                    className="city-btn"
                    value={item}
                  >
                    {item}
                  </ToggleButton>
                ))}
              </ToggleButtonGroup>
            </Container>
          </div>
        </div>
      </Container>
      <Container>
        <Row className="justify-content-md-center">
          {dynamiclocations.map((item, index) => (
            <Col md={6} sm={6} lg={4} className="my-3" key={index}>
              <Card className="branch_card">
                <Card.Header className="mb-3 mt-2 bg-white branch-subtitle border-0">
                  <FontAwesomeIcon icon={faMapPin} /> &nbsp; Branch Details
                </Card.Header>
                <Card.Body className="text-center body-address">
                  <h6>{item.branchName}</h6>
                  <Card.Text className="lh-sm">{item.branchAddress}</Card.Text>
                </Card.Body>
                <Card.Footer className="mb-2 mt-3 bg-white d-flex justify-content-evenly ">
                  <Card.Link
                    href={`http://maps.google.com/?q= ${item.Latitude} ,${item.Longitude}`}
                    target="_blank"
                  >
                    <AssistantDirectionTwoToneIcon sx={{ color: "#4A89F3" }} />
                  </Card.Link>
                  <Card.Link href="tel:+4733378901">
                    <PhoneTwoToneIcon
                      sx={{ color: "#4A89F3" }}
                      color="primary"
                    />
                  </Card.Link>
                </Card.Footer>
              </Card>
            </Col>
          ))}
        </Row>
      </Container>
    </section>
  );
};

export default OurLocation;
