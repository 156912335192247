import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { Radiology } from '../../customFunctions/Radiology';
import CardRadiologist from '../../screens/ContactUs/Doctors/CardRadiologist';

const Radiologists = () => {
    return (
        <section className="doctor-wrapper">
            <Container>
                <Row className='d-flex justify-content-center'>
                    {
                        Radiology.map(e => (
                            <CardRadiologist key={e.id} details={e} />
                        ))
                    }
                </Row>
            </Container>
        </section>
    );
};

export default Radiologists;