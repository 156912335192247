import React, { useState } from "react";
import { Container, Nav, Navbar } from "react-bootstrap";
import { Link } from "react-router-dom";
import logo from "../../images/expandedlogo.png";
import PhoneTwoToneIcon from "@mui/icons-material/PhoneTwoTone";
import "./Header.css";
import AuthService from "../../session/AuthService";

const Header = () => {
  // const { user, logout } = useAuth();
  const [expanded, setExpanded] = useState(false);
  const closeMenu = () => {
    setExpanded(false);
  };

  return (
    <>
      <div className="nav-support py-2 px-5 d-flex justify-content-end">
        <Link className="text-decort" to="/FAQ">FAQ'S</Link>
        {/* <Link className="ms-4" to="/Blog"> Blog</Link> */}
        <a className='ms-4' href="tel:9642396422">
        <PhoneTwoToneIcon sx={{ color: "#000" }} fontSize="small" color="primary" />
        96423 96422</a>
      </div>
      <div className="head-bg">
        <Navbar className="navbar" collapseOnSelect expand="lg"  expanded={expanded}>
          <Container fluid className="container-head">
            <Navbar.Brand href={AuthService.getBaseUrl()} >
              <img src={logo} className="image-size" alt="logo" />
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="basic-navbar-nav" expand="lg"  onClick={() => setExpanded(!expanded)}/>
            <Navbar.Collapse id="basic-navbar-nav">
              <Nav className="ms-auto align-items-center">
                <Link
                  to="/Knowyourrisk"
                  className="list-item text-decoration-none"  onClick={closeMenu}
                >
                  Know your risk
                </Link>
                <Link to="/MRI" className="list-item text-decoration-none" onClick={closeMenu}>  
                  Why MRI is better
                </Link>
                <Link
                  to="/OurLocations"
                  className="list-item text-decoration-none"  onClick={closeMenu}
                >
                  Our Locations
                </Link>
              </Nav>
              <div className="button-margin">
                <Link
                  to="/Pricing"
                  type="button"
                  className="button-yellow button text-decort"  onClick={closeMenu}
                >
                  Book Your Scan
                </Link>
              </div>
            </Navbar.Collapse>
          </Container>
        </Navbar>
      </div>
    </>
  );
};

export default Header;
