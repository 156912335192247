import AOS from "aos";
import "aos/dist/aos.css";
import React, { useEffect } from 'react';
import { Col } from 'react-bootstrap';
import aarthi from "../../../images/aarthi.jpg";
import arun from "../../../images/arun.jpg";
import prasanna from "../../../images/prasanna.jpg";
import './CardRadiologist.css';


const CardRadiologist = (props) => {
    const {name, status, course, id} = props.details;
    useEffect(() => {
        AOS.init({
                duration: 2000,
            });
        AOS.refresh();
      }, []);
    return (
        <Col md={6} lg={4} xl={4} xs={9} className="card-profile">
            <div className="single-feature-box sigle-doctor">
                <div className="doctors-profile" data-aos="fade-down">
                    <img src={id === "001" ? aarthi : id === "002"? arun : prasanna} alt={name} />
                </div>
                <div className="doctors-info" data-aos="fade-left">
                    <h6 className="mb-1"><a href=".#">{name}</a></h6>
                    <span className='postion-doctor'>{course}</span>
                </div>
                <div className="doctors-social" data-aos="flip-left">
                    <p className='doc-course'>{status}</p>
                </div>
            </div>
        </Col>
    );
};

export default CardRadiologist;