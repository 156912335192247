import React from "react";
import { Col, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import "./Promo.css";

const Promo = () => {
  return (
    <section className="promo-wrapper">
      {/* <Container> */}
      <div className="promo_container">
        <Row className="align-items-center">
          <Col md={12} sm={12} lg={6}>
            <div className="promo-content text-white text-start">
              <div className="top-content">
                <h1 className="mt-sm-req ">
                  Future of <br />
                  preventive health starts here !
                </h1>
              </div>
              <p>
                Get a clear picture of your health with our innovative, in-depth
                whole body screening.
              </p>
              <div className="button-segment d-grid gap-2">
                <Link to="/OurLocations" className="text-decort">
                  <button className="custom-btn border">Locations</button>
                </Link>
                <Link to="/AboutUs" className="text-decort">
                  <button className="custom-btn border">Know about us</button>
                </Link>
              </div>
            </div>
          </Col>
          <Col md={{ order: "first" }} sm={12} lg={6} className="">
            {/* <div className="promo-banner"> */}
            <div className="fun-fact-sec" data-aos="fade-right">
              <div className="single-fun sp-pad">
                <span>15</span>
                {/* <span>+</span> */}
                <p>15 states (and counting!)</p>
              </div>
              <div className="single-fun sp-fun" data-aos="fade-right">
                <span>65</span>
                <span>+</span>
                <p>65+ Branches. </p>
              </div>
              <div className="single-fun sp-pad" data-aos="fade-left">
                <span>5500</span>
                <span>+</span>
                <p>5500+ Whole Body MRI scans completed.</p>
              </div>
              <div className="single-fun sp-fun" data-aos="fade-left">
                <span>98</span>
                <span>%</span>
                <p>98% Customer satisfaction score.</p>
              </div>
              <span className="line"></span>
              {/* </div> */}
              {/* <img className="mx-auto d-block" src={promoBanner} alt="" /> */}
            </div>{" "}
            <div
              data-aos="fade-right"
              className="d-flex justify-content-center pb-4 "
            >
              <span>A unit of Aarthi scans and Labs. </span>
            </div>
          </Col>
        </Row>
      </div>
      {/* </Container> */}
    </section>
  );
};

export default Promo;
