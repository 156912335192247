import "bootstrap/dist/css/bootstrap.min.css";
import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import "./index.css";
import "./indexResponsive.css";
import store from "./redux/store";
import Navigations from "./routers/Navigations";

// const rootRenderer = ReactDOM.createRoot(document.getElementById("root"));

// rootRenderer.render(
//   <Container fluid className="appContainer">
//     <Provider store={store}>
//       <Navigations />
//     </Provider>
//   </Container>
// );

ReactDOM.render(
  <div className="App">
    <Provider store={store}>
      <Navigations />
    </Provider>
  </div>,
  document.getElementById("root")
);

console.log("bBBBBBB", process.env.NODE_ENV);
if (["production"].includes(process.env.NODE_ENV)) {
  console.log = () => {};
  console.error = () => {};
  console.debug = () => {};
}
